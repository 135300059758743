<div class="cancellink" (click)="close()">
    <span>Cancel</span>
</div>
<h1>Update status</h1>

<div class="updatestatusbox">
    <div class="statustopinfobox" *ngIf="currentStatusObj">
        <div class="topinfoitem"><span class="fa fa-calendar"></span>{{currentStatusObj.scheduleDate | customdate: 'ddd - MMM DD, YYYY'}}</div>
        <div class="topinfoitem"><span class="fa fa-clock-o"></span>{{currentStatusObj.scheduleTime}}</div>
        <div class="topinfoitem"><span class="fa fa-map-marker"></span>{{currentStatusObj.visitAddressPostalCode}} {{currentStatusObj.visitAddressCity}}</div>
    </div>

    <div class="statusbox">
        <div class="statusitem selectable" [class.selected]="newStatus == 'inprogress'" (click)="selectStatus('inprogress')">
            <div class="statusTxt">In progress</div>
            <div class="selectedIco" *ngIf="newStatus == 'inprogress'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatus == 'complete'" (click)="selectStatus('complete')">
            <div class="statusTxt">Completed</div>
            <div class="selectedIco" *ngIf="newStatus == 'complete'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatus == 'reschedule'" (click)="selectStatus('reschedule')">
            <div class="statusTxt">Rescheduled</div>
            <div class="selectedIco" *ngIf="newStatus == 'reschedule'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatus == 'cancel'" (click)="selectStatus('cancel')">
            <div class="statusTxt">Canceled</div>
            <div class="selectedIco" *ngIf="newStatus == 'cancel'">
                <span class="fa fa-check"></span>
            </div>
        </div>
    </div>

    <div class="statusbox" *ngIf="newStatus == 'cancel'">
        <h3>Reason for cancelling</h3>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Illness'" (click)="selectStatusReason('Illness')">
            <div class="statusTxt">Illness</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Illness'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Client found cheaper alternative'" (click)="selectStatusReason('Client found cheaper alternative')">
            <div class="statusTxt">Client found cheaper alternative</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Client found cheaper alternative'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Client found faster alternative'" (click)="selectStatusReason('Client found faster alternative')">
            <div class="statusTxt">Client found faster alternative</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Client found faster alternative'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Client did the repair on their own'" (click)="selectStatusReason('Client did the repair on their own')">
            <div class="statusTxt">Client did the repair on their own</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Client did the repair on their own'">
                <span class="fa fa-check"></span>
            </div>
        </div>
    </div>
    <div class="statusbox" *ngIf="newStatus == 'complete'">
        <h3>Additional details</h3>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Diagnosis & Repair done'" (click)="selectStatusReason('Diagnosis & Repair done')">
            <div class="statusTxt">Diagnosis & Repair done</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Diagnosis & Repair done'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Diagnosis done'" (click)="selectStatusReason('Diagnosis done')">
            <div class="statusTxt">Diagnosis done</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Diagnosis done'">
                <span class="fa fa-check"></span>
            </div>
        </div>
    </div>
    <div class="statusbox" *ngIf="newStatus == 'reschedule'">
        <h3>Additional details</h3>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Illness'" (click)="selectStatusReason('Illness')">
            <div class="statusTxt">Illness</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Illness'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Customer unavailable'" (click)="selectStatusReason('Customer unavailable')">
            <div class="statusTxt">Customer unavailable</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Customer unavailable'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Technicians unavailable'" (click)="selectStatusReason('Technicians unavailable')">
            <div class="statusTxt">Technicians unavailable</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Technicians unavailable'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="newStatusReason == 'Spare parts or materials unavailable'" (click)="selectStatusReason('Spare parts or materials unavailable')">
            <div class="statusTxt">Spare parts or materials unavailable</div>
            <div class="selectedIco" *ngIf="newStatusReason == 'Spare parts or materials unavailable'">
                <span class="fa fa-check"></span>
            </div>
        </div>
    </div>
    <div class="btnbox">
        <button class="btnprimary" (click)="createNewOrderStatus()">Update status</button>
    </div>
</div>