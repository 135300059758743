import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { CurrentOrderStatusObj, NewOrderStatusArg } from 'src/app/shared/models/orderstatus';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.css']
})
export class UpdateStatusComponent implements OnInit {

  @Input()
  public orderId: number = null;
  
  public currentStatusObj:CurrentOrderStatusObj = null;
  
  public newStatus: string='';
  public newStatusReason: string = '';


  @Output() sliderClosed: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.loadRecords();
  }

  createNewOrderStatus()
  {
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();

    let newStatusArg: NewOrderStatusArg=new NewOrderStatusArg();
    newStatusArg.orderId = this.orderId;
    newStatusArg.status = this.newStatus;
    newStatusArg.statusReason = this.newStatusReason;
    newStatusArg.userId = loginDetail.userId;

    this.dataService.createNewOrderStatus(newStatusArg).subscribe(res=>{
      switch(res.responseCode)
      {
        case "00":
          this.toastr.successToastr('status updated successfully');
          this.sliderClosed.emit({isUpdated: true});
          break;
        case "01":
          this.toastr.errorToastr('Status could not be updated, because this status is already assigned to this order.', 'Error!');
          break;
      }
    });
  }

  close()
  {
    this.sliderClosed.emit(null);
  }

  selectStatus(status: string)
  {
    this.newStatus = status;
  }
  selectStatusReason(statusReason: string)
  {
    this.newStatusReason = statusReason;
  }

  public loadRecords():void{
    this.dataService.getOrderCurrentStatusObj(this.orderId).subscribe(res=>{
      this.currentStatusObj = res;
    });
  }

}
