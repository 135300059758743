import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FirstTimeChangePasswordArg, LoginResponseObj } from 'src/app/shared/models/login';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-change-first-password',
  templateUrl: './change-first-password.component.html',
  styleUrls: ['./change-first-password.component.css']
})
export class ChangeFirstPasswordComponent implements OnInit {

  public form: FormGroup = null;

  constructor(private fb: FormBuilder, private activatedRoue: ActivatedRoute, private dataService: DataService, private modelService: ModelService,  private toastr: ToastrManager, private router: Router) 
  { 
    this.form = this.fb.group({
      email: [this.activatedRoue.snapshot.params.email, [Validators.required, Validators.maxLength(100)]],
      oldPassword: ['', [Validators.required, Validators.maxLength(100)]],
      newPassword: ['', [Validators.required, Validators.maxLength(100)]],
      confirmNewPassword: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  ngOnInit() {
  }

  changePassword()
  {
    let changePasswordArg : FirstTimeChangePasswordArg = {...this.form.getRawValue()};
    if(changePasswordArg.newPassword != changePasswordArg.confirmNewPassword)
    {
      this.toastr.errorToastr('New password and confirm password must match','Error!');
    }
    else
    {
      this.dataService.changePasswordFirstTime(changePasswordArg).subscribe((res:LoginResponseObj)=>{
        if(res.responseCode=="00")
        {
          this.toastr.successToastr('Password changed successfully', 'Password Changed');
          this.modelService.setLoginDetail(res);
          this.router.navigate(['/home']);
        }
        else
        {
          if(res.responseCode == "01")
          {
            this.toastr.errorToastr("Old password mismatch!","Password Mismatch!"); 
          }
          else
          {
            this.toastr.errorToastr("Some error occured!","Error!"); 
          }
        }
      });
    }
  }

}
