<h3>{{getSparePartTitle()}}</h3>
<h3 class="small">Select spare part status</h3>
<div class="statusbox">
    <div class="statusitem selectable" [class.selected]="selectedStatus == 'Used'" (click)="setSparePartStatus('Used')">
        <div class="statusTxt">Used</div>
        <div class="selectedIco" *ngIf="selectedStatus == 'Used'">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="statusitem selectable" [class.selected]="selectedStatus == 'Not used but opened'" (click)="setSparePartStatus('Not used but opened')">
        <div class="statusTxt">Not used but opened</div>
        <div class="selectedIco" *ngIf="selectedStatus == 'Not used but opened'">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="statusitem selectable" [class.selected]="selectedStatus == 'DOA'" (click)="setSparePartStatus('DOA')">
        <div class="statusTxt">DOA</div>
        <div class="selectedIco" *ngIf="selectedStatus == 'DOA'">
            <span class="fa fa-check"></span>
        </div>
    </div>
</div>
<form [formGroup]="form" class="form-horizontal" appIdentityRevealed>
    <div class="pricerow">
        <div class="form-group" style="width:150px;">
            <label>Price per unit (€)</label>
            <input type="number" class="form-control" formControlName="itemPrice" />
        </div>
        <div class="form-group" style="width:80px;">
            <label>Qty</label>
            <input type="number" class="form-control" formControlName="itemQuantity" />
        </div>
    </div>
</form>
<div class="btnbox">
    <button class="btnprimary" [disabled]="!form.valid" (click)="updateSparePartStatus()">Update spare part</button>
</div>