<div class="wbheading">
    <img src="./../../../../assets/images/step1.png" />
    <h2>Tasks performed & time details</h2>
</div>
<div class="taskbox" [formGroup]="form">
    <p class="infocaption">Tap on the perfomed tasks</p>
    <div class="taskitem">
        <label>Driving</label>
        <mat-slider min="0" max="300" step="1" thumbLabel tickInterval="1" value="1" displayValue="test" color="primary" formControlName="drivingMinutes"></mat-slider>
        <label class="lblFrom">0 hrs</label>
        <label class="lblTo">5 hrs</label>
    </div>
    <div class="taskitem">
        <label>Inspection</label>
        <mat-slider min="0" max="300" step="1" thumbLabel tickInterval="1" value="1" color="primary" formControlName="inspectionMinutes"></mat-slider>
        <label class="lblFrom">0 hrs</label>
        <label class="lblTo">5 hrs</label>
    </div>
    <div class="taskitem">
        <label>Repair</label>
        <mat-slider min="0" max="300" step="1" thumbLabel tickInterval="1" value="1" color="primary" formControlName="repairMinutes"></mat-slider>
        <label class="lblFrom">0 hrs</label>
        <label class="lblTo">5 hrs</label>
    </div>
    <div class="taskitem">
        <label>Consultation</label>
        <mat-slider min="0" max="300" step="1" thumbLabel tickInterval="1" value="1" color="primary" formControlName="consultationMinutes"></mat-slider>
        <label class="lblFrom">0 hrs</label>
        <label class="lblTo">5 hrs</label>
    </div>
    <div class="taskitem">
        <label>Other tasks</label>
        <mat-slider min="0" max="300" step="1" thumbLabel tickInterval="1" value="1" color="primary" formControlName="otherTaskMinutes"></mat-slider>
        <label class="lblFrom">0 hrs</label>
        <label class="lblTo">5 hrs</label>
    </div>
</div>
<div class="btnbox">
    <button class="btnprimary" (click)="next()">Next</button>
</div>