<div class="loginpage">
    <div class="fixfirstlogo">
        <svg width="238" height="70" viewBox="0 0 238 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.94792 69.9995V31.976H0V23.654H5.94792V21.2811C5.94792 10.4275 12.8799 1.96081 26.4078 1.96081C30.7392 1.96081 35.5026 3.09983 37.8787 4.65093L35.665 13.2274C33.6798 12.1082 30.4178 10.9818 26.4768 10.9818C18.9748 10.9818 16.6662 15.7684 16.6662 21.7273V23.654H41.5098V69.9995H30.7373V31.976H16.7205V69.9995H5.94792Z" fill="#2A4054"/>
            <path d="M104.22 69.9999V27.5965H97.5039V23.8464H104.22V21.0578C104.22 8.55783 109.925 1.52588e-05 122.163 1.52588e-05C126.119 1.52588e-05 130.903 1.53856 133.204 3.46163L130.996 7.11573C128.879 5.38512 125.291 3.9426 121.334 3.9426C111.489 3.9426 108.729 12.2119 108.729 21.1543V23.8464H135.872V69.9999H131.364V27.5965H108.729V69.9999H104.22Z" fill="#2A4054"/>
            <path d="M151.595 38.6821C151.595 33.6714 151.503 28.7567 151.227 23.7465H155.376L155.561 33.7674H155.837C158.05 27.3114 162.845 22.59 169.392 22.59C170.129 22.59 170.867 22.6865 171.512 22.8789V27.2154C170.683 27.0225 169.945 27.0225 168.931 27.0225C162.753 27.0225 158.05 32.4185 156.575 40.4168C156.298 41.7657 156.113 43.4035 156.113 44.9457V70H151.595V38.6821Z" fill="#2A4054"/>
            <path d="M179.476 62.9271C182.025 64.5807 185.805 66.234 190.024 66.234C197.407 66.234 200.659 62.1925 200.659 57.6915C200.659 52.5475 197.32 50.0669 190.727 47.8626C183.08 45.2908 179.3 41.1574 179.3 35.9214C179.3 29.6752 184.046 23.7042 193.188 23.7042C197.32 23.7042 200.923 24.8979 203.384 26.6439L201.714 30.3179C200.308 29.3077 197.231 27.4705 192.397 27.4705C186.595 27.4705 183.519 31.1445 183.519 35.3699C183.519 40.3303 187.123 42.2591 193.364 44.4642C201.099 47.2196 204.879 50.7099 204.879 57.3236C204.879 64.4888 199.253 69.9999 189.672 69.9999C185.189 69.9999 181.058 68.7142 177.894 66.785L179.476 62.9271Z" fill="#2A4054"/>
            <path d="M224.324 10.2988V23.7438H238V27.41H224.324V56.2732C224.324 61.8208 225.944 66.0515 231.252 66.0515C233.861 66.0515 235.66 65.6755 236.919 65.2054L237.46 68.7781C235.931 69.4361 233.681 69.9998 230.802 69.9998C227.383 69.9998 224.594 68.8721 222.796 66.6151C220.636 64.0769 219.916 59.9399 219.916 55.7092V27.41H211.999V23.7438H219.916V11.7094L224.324 10.2988Z" fill="#2A4054"/>
            <path d="M95.2324 0.574074L71.1713 39.284L61.3324 23.6656H50.4365L65.6309 46.8329H77.049L95.2324 18.1407V0.574074Z" fill="#48ABE1"/>
            <path d="M50.4365 69.9999H61.3324L71.1713 54.3819L80.9501 69.9999H92.1588L77.0486 46.833H65.6309L50.4365 69.9999Z" fill="#2A4054"/>
        </svg>            
    </div>
    <h2 class="small">Log in</h2>
    <form [formGroup]="form" class="form-horizontal frmlogin" appIdentityRevealed>
        <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control txtboxbg" formControlName="emailAddress" />
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control txtboxbg" formControlName="password" />
        </div>
        <div class="btnprimarycontainer">
            <button type="submit" class="btnprimary" (click)="login()">Log in</button>
        </div>
    </form>
</div>