<div class="cancellink" (click)="close()">
    <span>Cancel</span>
</div>
<div class="toplinkrow">
    <div><span class="fa fa-chevron-left" *ngIf="isBackBtnVisible()" (click)="moveBack()"></span></div>
    <h1>Work report</h1>
    <div></div>
</div>
<div class="wbcontainer">
    <div *ngIf="panelMode == 'TASK'">
        <wbtasks (onStepCompleted)="stepCompleted($event, 'TASK')" *ngIf="workReportObj" [orderId]="orderId" [workReportObj]="workReportObj"></wbtasks>
    </div>
    <div *ngIf="panelMode == 'SPARE_PARTS'">
        <wbspare-parts (onStepCompleted)="stepCompleted($event, 'SPARE_PARTS')" *ngIf="workReportObj" [orderId]="orderId" [workReportObj]="workReportObj"></wbspare-parts>
    </div>
    <div *ngIf="panelMode == 'PROBLEMS'">
        <wbproblem (onStepCompleted)="stepCompleted($event, 'PROBLEMS')" *ngIf="workReportObj" [orderId]="orderId" [workReportObj]="workReportObj"></wbproblem>
    </div>
    <div *ngIf="panelMode == 'WORK_PROOF'">
        <wbwork-proof (onStepCompleted)="stepCompleted($event, 'WORK_PROOF')" *ngIf="workReportObj" [orderId]="orderId" [workReportObj]="workReportObj"></wbwork-proof>
    </div>
    <div *ngIf="panelMode == 'SIGNATURE'">
        <wbsignatures (onStepCompleted)="stepCompleted($event, 'SIGNATURE')" *ngIf="workReportObj" [orderId]="orderId" [workReportObj]="workReportObj"></wbsignatures>
    </div>
    <div *ngIf="panelMode == 'HOURLY_RATE'">
        <wbhourly-rate (onStepCompleted)="stepCompleted($event, 'HOURLY_RATE')" [orderId]="orderId"></wbhourly-rate>
    </div>
    <div *ngIf="panelMode == 'SUMMARY'">
        <wbsummary (onStepCompleted)="stepCompleted($event, 'SUMMARY')" *ngIf="workReportObj" [orderId]="orderId"></wbsummary>
    </div>
</div>