import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkReportObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'wbsummary',
  templateUrl: './wbsummary.component.html',
  styleUrls: ['./wbsummary.component.css']
})
export class WBSummaryComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public workReportObj:WorkReportObj = null;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getWorkReport(this.orderId).subscribe(res=>{
      this.workReportObj = res;
      console.log(res);
    });
  }

  next()
  {
    this.onStepCompleted.emit(null); 
  }
  getFeedbackLink():string
  {
    let link =`https://fixfirst.typeform.com/to/r1WWlA0b?fixfirst_id=${this.workReportObj.fixFirstId}`;

    return link;
  }

}
