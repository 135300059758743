import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'decline-estimation',
  templateUrl: './decline-estimation.component.html',
  styleUrls: ['./decline-estimation.component.css']
})
export class DeclineEstimationComponent implements OnInit {

  public declineReason: string='';

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
  }

  public selectDeclineReason(declineReason: string):void{
    this.declineReason = declineReason;
  }

  sendFeedback():void
  {
    this.dataService.declineCostEstimation(this.orderId, this.declineReason).subscribe(res=>{
      this.toastr.successToastr('Estimation declined', 'Estimation Declined');
      this.onStepCompleted.emit(null);
    });
  }

}
