import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CostEstimationObj } from 'src/app/shared/models/costestimate';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'accept-estimation',
  templateUrl: './accept-estimation.component.html',
  styleUrls: ['./accept-estimation.component.css']
})
export class AcceptEstimationComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public costEstimationObj: CostEstimationObj=null;

  constructor(private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.loadRecords();
  }

  public accept()
  {
    this.dataService.acceptCostEstimation(this.orderId).subscribe(res=>{
      this.toastr.successToastr('Estimation Accepted', 'Estimation Accepted');
      this.onStepCompleted.emit(null);
    });
  }

  private loadRecords():void{
    this.dataService.getCostEstimationObj(this.orderId).subscribe(res=>{
      this.costEstimationObj = res;
    });
  }

}
