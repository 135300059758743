export class LoginArg{
    public email: string;
    public password: string;
}
export class LoginResponseObj{
    public responseCode: string;
    public userId: number;
    public userName: string;
    public isPasswordChanged: boolean;
}
export class FirstTimeChangePasswordArg
{
    public email: string;
    public oldPassword: string;
    public newPassword: string;
    public confirmNewPassword: string;
}