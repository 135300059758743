import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SecureComponent } from './secure/secure.component';
import { HomeComponent } from './home/home.component';
import { OrdersListLayoutComponent } from './orders-list-layout/orders-list-layout.component';
import { OrdersListComponent } from './orders-list-layout/orders-list/orders-list.component';
import { OrdersCalendarComponent } from './orders-list-layout/orders-calendar/orders-calendar.component';
import { OrderDetailsLayoutComponent } from './order-details-layout/order-details-layout.component';
import { OrderDetailsComponent } from './order-details-layout/order-details/order-details.component';
import { WelcomeComponent } from './login/welcome/welcome.component';
import { ChangeFirstPasswordComponent } from './login/change-first-password/change-first-password.component';
import { MoreToolsComponent } from './more-tools/more-tools.component';
import { VisitPreparationComponent } from './order-details-layout/visit-preparation/visit-preparation.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'first-login-change-password/:email', component: ChangeFirstPasswordComponent },
      {
        path: '', component: SecureComponent, children: [
          { path: 'home', component: HomeComponent },
          { 
            path: 'orders-list', component: OrdersListLayoutComponent, children:[
              {path:'', component: OrdersListComponent}
            ] 
          },
          {
            path:'orders-details', component: OrderDetailsLayoutComponent, children:[
              {path:':id', component: OrderDetailsComponent},
              {path:'visit-preparation/:id', component: VisitPreparationComponent}
            ]
          },
          { path:'more-tools/:id', component: MoreToolsComponent}
        ]
      },
      { path: '**', redirectTo: 'welcome' }
    ]),
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
