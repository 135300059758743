import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'spare-parts',
  templateUrl: './spare-parts.component.html',
  styleUrls: ['./spare-parts.component.css']
})
export class SparePartsComponent implements OnInit {

  @Input()
  public serialNo:number;

  @Input()
  public form:FormGroup = null;

  constructor() { }

  ngOnInit() {
  }

}
