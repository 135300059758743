<div class="cancellink" (click)="close()">
    <span>Cancel</span>
</div>
<h1>Estimate</h1>


<div *ngIf="panelMode == 'FORM'">
    <cost-estimate (onStepCompleted)="stepCompleted($event, 'FORM')" [orderId]="orderId"></cost-estimate>
</div>
<div *ngIf="panelMode == 'SUMMARY'">
    <cost-estimate-summary (onStepCompleted)="stepCompleted($event, 'SUMMARY')" [orderId]="orderId"></cost-estimate-summary>
</div>
<div *ngIf="panelMode == 'ACCEPT'">
    <accept-estimation (onStepCompleted)="stepCompleted($event, 'ACCEPT')" [orderId]="orderId"></accept-estimation>
</div>
<div *ngIf="panelMode == 'DECLINE'">
    <decline-estimation (onStepCompleted)="stepCompleted($event, 'DECLINE')" [orderId]="orderId"></decline-estimation>
</div>