import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkReportObj } from 'src/app/shared/models/workreport';

@Component({
  selector: 'wbsignatures',
  templateUrl: './wbsignatures.component.html',
  styleUrls: ['./wbsignatures.component.css']
})
export class WBSignaturesComponent implements OnInit {

  @Input()
  public workReportObj: WorkReportObj = null;
  
  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  next()
  {
    this.onStepCompleted.emit(null); 
  }

}
