<div class="spFormBox">
    <h4>Spare part or material #{{serialNo}}</h4>
    <form [formGroup]="form" class="form-horizontal" appIdentityRevealed>
        <div class="row1">
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="itemName" />
            </div>
            <div class="form-group" style="width:80px;">
                <label>Qty</label>
                <input type="number" class="form-control" formControlName="itemQuantity" />
            </div>
        </div>
        <div class="row2">
            <div class="form-group">
                <label>Price per unit (€)</label>
                <input type="number" class="form-control" formControlName="itemPrice" />
            </div>
        </div>
    </form>
</div>