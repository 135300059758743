<div class="wbheading">
    <img src="./../../../../assets/images/step3.png" />
    <h2>Problem & solutions</h2>
</div>
<div [formGroup]="form">
    <p class="infocaption">Problem</p>
    <div class="txtbox">
        <textarea class="form-control" rows="4" cols="20" placeholder="please enter problem here" formControlName="problemTxt"></textarea>
    </div>
    <!-- <div class="dropdownbox" *ngIf="isProblemSelected">
        <div class="topitem">
            <div class="itemtxt"><strong>Selected problem</strong></div>
            <div class="itemico"><span class="fa fa-pencil" (click)="editProblem()"></span></div>
        </div>
        <div class="statusbox">
            <div class="statusitem selected">
                <div class="statusTxt">{{lstProblems[selectedProblemIndex]}}</div>
            </div>
        </div>
    </div>
    <div class="dropdownbox customscrollbar" *ngIf="!isProblemSelected" [@slideInOut]="isProblemListExpanded ? 'expanded' : 'normal'">
        <div class="topitem" (click)="toggleProblemExpand()">
            <div class="itemtxt">Select problem</div>
            <div class="itemico"><span class="fa" [class.fa-chevron-down]="!isProblemListExpanded" [class.fa-chevron-up]="isProblemListExpanded"></span></div>
        </div>
        <div class="statusbox">
            <div class="statusitem" [class.selected]=" i == selectedProblemIndex" (click)="selectProblem(i)" *ngFor="let item of lstProblems; let i = index;">
                <div class="statusTxt">{{item}}</div>
            </div>
        </div>
    </div> -->

    <p class="infocaption">Solution</p>
    <div class="txtbox">
        <textarea class="form-control" rows="4" cols="20" placeholder="please enter solution here" formControlName="solutionTxt"></textarea>
    </div>
    <!-- <div class="dropdownbox" *ngIf="isSolutionSelected">
        <div class="topitem">
            <div class="itemtxt"><strong>Selected solution</strong></div>
            <div class="itemico"><span class="fa fa-pencil" (click)="editSolution()"></span></div>
        </div>
        <div class="statusbox">
            <div class="statusitem selected">
                <div class="statusTxt">{{lstSolutions[selectedSolutionIndex]}}</div>
            </div>
        </div>
    </div>
    <div class="dropdownbox customscrollbar" *ngIf="!isSolutionSelected"  [@slideInOut]="isSolutionsListExpanded ? 'expanded' : 'normal'">
        <div class="topitem" (click)="toggleSolutionExpand()">
            <div class="itemtxt">Select solution</div>
            <div class="itemico"><span class="fa" [class.fa-chevron-down]="!isSolutionsListExpanded" [class.fa-chevron-up]="isSolutionsListExpanded"></span></div>
        </div>
        <div class="statusbox">
            <div class="statusitem" (click)="selectSolution(i)" [class.selected]="selectedSolutionIndex == i" *ngFor="let item of lstSolutions; let i = index;">
                <div class="statusTxt">{{item}}</div>
            </div>
        </div>
    </div> -->
</div>
<div class="btnbox">
    <button class="btnprimary" [disabled]="!form.valid" (click)="next()">Next</button>
</div>