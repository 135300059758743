export class WorkReportObj
{
    public orderId: number;

    public fixFirstId: string;
    public fixFirstOrderId: string;
    public customerFirstName: string;
    public customerLastName: string;
    public spFirstName: string;
    public spLastName: string;
    public technicianFirstName: string;
    public technicianLastName: string;
    public visitType: string;
    public orderStatus: string;
    public orderStatusReason: string;
    public applianceType: string;
    public scheduleDate: any;
    public scheduleTime: string;
    public visitAddressPostalCode: string;
    public visitAddressCity: string;
    public requestDate: any;
    
    
    public drivingMinutes: number;
    public inspectionMinutes: number;
    public repairMinutes: number;
    public consultationMinutes: number;
    public otherTaskMinutes: number;

    public problemTxt: string;
    public solutionTxt: string;

    public hourlyRate: number;
    public drivingLumpSum: number;
    public labourHours: number;
    public labourMinutes: number;
    public sparePartsTotal: number;
    public sparePartsTax: number;
    public labourTotal: number;
    public labourTax: number;
    public grandTotal: number;

    public lstCESpareParts : WRSparePartObj[] = [];

    public lstSpareParts: WRSparePartObj[] = [];
}
export class WRSparePartObj{
    public itemName: string;
    public itemStatus: number;
    public itemQuantity: number;
    public itemPrice: number;
    public costEstimateSparePartId: number;
}

export class WRTasksObj
{
    public orderId: number;

    public drivingMinutes: number;
    public inspectionMinutes: number;
    public repairMinutes: number;
    public consultationMinutes: number;
    public otherTaskMinutes: number;
}
export class WRProblemsObj
{
    public orderId: number;
    
    public problemTxt: string;
    public solutionTxt: string;
}

export class WRHourlyRateObj
{
    public orderId: number;
    
    public drivingLumpSum: number;

    public hourlyRate: number;

    public inspectionMinutes: number;
    public repairMinutes: number;
    public consultationMinutes: number;
    public otherTaskMinutes: number;


}