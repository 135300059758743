import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export module Util
{
  export function ParseCustomDate(value: string):NgbDate{
    if (!value)
    return null;
    let parts=value.split('.');
    return {year:+parts[2],month:+parts[1],day:+parts[0]} as NgbDate
  }

    export function StringToNgbDate(dateString: string):NgbDate{
        let dt = moment(dateString);

        let ngbDate = new NgbDate(dt.year(), dt.month() + 1, dt.date());

        return ngbDate;
      }

      export function NgbDateToString(ngbDate: NgbDate):string{
        let strDt = moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`).format('YYYY-MM-DD');

        return strDt;
      }
}