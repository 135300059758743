<mat-drawer-container class="formgroup-sidenavcontainer" [hasBackdrop]="true">
    <mat-drawer #sidenav mode="over" [opened]="isSlidingPanelOpened()" position="end" class="filtersidenav" [disableClose]="true">
      <div class="matDrawerContent">
          <div *ngIf="showFilter">
              <orders-filter (sliderClosed)='closeSlider($event, "FILTER")'></orders-filter>
          </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="topheadingbox">
            <h1>Orders</h1>
            <div class="rightico">
                <button class="btnround" (click)="showFilterPanel()"><span class="fa fa-filter"></span> Filter</button>
            </div>
        </div>
        <div class="searchbox">
            <input class="searchtxt" type="text" placeholder="Search" />
        </div>
        <!-- <div class="tabnav">
            <ul>
                <li class="selected">
                    <span class="fa fa-list"></span> List
                </li>
                <li>
                    <a routerLink=""><span class="fa fa-calendar"></span>Calendar</a>
                </li>
            </ul>
        </div> -->
        <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>