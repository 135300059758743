<mat-drawer-container class="formgroup-sidenavcontainer" [hasBackdrop]="true">
    <mat-drawer #sidenav mode="over" [opened]="isSlidingPanelOpened()" position="end" class="filtersidenav" [disableClose]="true">
      <div class="matDrawerContent">
          <div *ngIf="showUpdateStatus">
              <update-status [orderId]="orderId" (sliderClosed)='closeSlider($event, "UPDATE_STATUS")'></update-status>
          </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="topheadingbox">
            <a routerLink="/orders-list"><span class="fa fa-angle-left"></span> <span class="txt">Orders</span></a>
            <div class="rightico">
                <button class="btnround" (click)="navigateToMoreTools()"><span class="fa fa-ellipsis-h"></span> More</button>
            </div>
        </div>
        <h1>Order details</h1>

        <div class="tabnav">
            <ul>
                <li [class.selected]="rla1.isActive">
                    <a routerLinkActive="selected" [routerLink]="[this.orderId]" #rla1="routerLinkActive">Order summary</a>
                </li>
                <li [class.selected]="rla2.isActive">
                    <a routerLinkActive="selected" [routerLink]="['visit-preparation',this.orderId]" #rla2="routerLinkActive">Visit preparation</a>
                </li>
            </ul>
        </div>
        <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>