<h2 class="small">Summary</h2>
<div class="summarybox" *ngIf="costEstimationObj">
    <p class="infocaption">Order information</p>
    <div class="infobox">
        <div class="inforow">
            <label class="captiontxt">Order-ID:</label>
            <label class="valuetxt">{{costEstimationObj.fixFirstOrderID}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">FixFirst-ID:</label>
            <label class="valuetxt">{{costEstimationObj.fixFirstID}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Appliance:</label>
            <label class="valuetxt">{{costEstimationObj.appliance}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Customer:</label>
            <label class="valuetxt">{{costEstimationObj.customerFirstName}} {{costEstimationObj.customerLastName}}</label>
        </div>
    </div>
    <p class="infocaption">Material & spare parts</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <th>Concept</th>
                    <th>Qty</th>
                    <th>Price (€)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of costEstimationObj.lstSpareParts">
                    <td>{{item.itemName}}</td>
                    <td>{{item.itemQuantity}}</td>
                    <td>{{item.itemPrice}}</td>
                </tr>
                <tr>
                    <td><strong>Subtotal</strong></td>
                    <td></td>
                    <td><strong>{{costEstimationObj.sparePartsTotal.toFixed(2)}}</strong></td>
                </tr>
                <tr>
                    <td><strong>Tax 21%</strong></td>
                    <td></td>
                    <td><strong>{{costEstimationObj.sparePartsTax.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <p class="infocaption">Hour rate</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <th>Concept</th>
                    <th>Qty</th>
                    <th>Price (€)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Hour rate
                        <br />
                        @{{costEstimationObj.hourlyRate}}/hour
                    </td>
                    <td>{{costEstimationObj.repairHours}},{{costEstimationObj.repairMinutes}}</td>
                    <td>{{costEstimationObj.hourlyRateTotal.toFixed(2)}}</td>
                </tr>
                <tr>
                    <td><strong>Subtotal</strong></td>
                    <td></td>
                    <td><strong>{{costEstimationObj.hourlyRateTotal.toFixed(2)}}</strong></td>
                </tr>
                <tr>
                    <td><strong>Tax 21%</strong></td>
                    <td></td>
                    <td><strong>{{costEstimationObj.hourlyRateTax.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <p class="infocaption">TOTAL (€)</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <tbody>
                <tr>
                    <td>Materials & spare parts</td>
                    <td>{{(costEstimationObj.sparePartsTotal + costEstimationObj.sparePartsTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td>Hour rate</td>
                    <td>{{(costEstimationObj.hourlyRateTotal + costEstimationObj.hourlyRateTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td><strong>Grand total</strong></td>
                    <td><strong>{{costEstimationObj.grandTotal.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="btnbox">
        <button class="btnoutline" (click)="declineEstimation()">Decline</button>
        <br /><br />
        <button class="btnprimary" (click)="acceptEstimation()">Accept</button>
    </div>
</div>