<!-- <h1>Orders List</h1> -->
<div *ngIf="isNoRecords" class="norecordsbox">
    <h3>No orders here.</h3>
    <br /><br />
    <h3>Please try adjusting the filters to see relevant ones.</h3>
</div>

<div *ngIf="!isNoRecords" class="orderlisting">
    <div class="orderbox" *ngFor="let item of lstOrders" (click)="navigateToDetail(item.orderId)">
        <div class="leftcol">
            <div class="icotoolbox">
                <div class="icotool"></div>
                <div class="tooltxt">
                    <h3>{{item.visitType}}</h3>
                    <p class="pmain">FixFirst</p>
                </div>
            </div>
            <div class="leftinfocellsmall">{{item.applianceType}}</div>
            <div class="leftinfocell"><span class="fa fa-calendar"></span>{{item.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{item.scheduleTime}}</div>
            <div class="leftinfocell"><span class="fa fa-map-marker"></span>{{item.customerPostalCode}} {{item.customerCity}}</div>
        </div>
        <div>
            <div class="rightinfocell">
                <span class="bdgbox" [statusbg]="item.status">{{item.status | orderstatus}}</span>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>