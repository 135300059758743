<div *ngIf="mode == 'SPARE_PARTS'">
    <div class="wbheading">
        <img src="./../../../../assets/images/step2.png" />
        <h2>Spare parts & materials used</h2>
    </div>
    <div class="materialsbox">
        <h3 class="small">Spare parts</h3>
        <p class="infocaption">Update the status of all the spare parts</p>
        <div class="statusbox">
            <div *ngFor="let item of lstSpareParts.controls; let i=index">
                <wbspare-part-unit [form]="item" [index]="i" (onStepCompleted)="showUpdateSparePartsPanel($event)"></wbspare-part-unit>
            </div>
        </div>
        <p class="infocaption">Input any other spare used</p>
        <div>
            <input type="text" class="form-control" [(ngModel)]="newSparePartTitle"/>
        </div>
        <div class="addLinkBox">
            <a (click)="addSparePart()"><span class="fa fa-plus-circle"></span> Add spare parts or material</a>
        </div>
    </div>
    <div class="btnbox">
        <button class="btnprimary" [disabled]="!form.valid" (click)="next()">Next</button>
    </div>
</div>
<div *ngIf="mode == 'SPARE_PARTS_STATUS'">
    <wbspare-part-status (onSparePartStatusUpdated)="updateSparePartStatus($event)" [form]="lstSpareParts.controls[sparePartsArrIndex]" [index]="sparePartsArrIndex"></wbspare-part-status>
</div>