import { Component, OnInit } from '@angular/core';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-orders-list-layout',
  templateUrl: './orders-list-layout.component.html',
  styleUrls: ['./orders-list-layout.component.css']
})
export class OrdersListLayoutComponent implements OnInit {

  public showFilter: boolean = false;

  constructor(private modelService: ModelService) { }

  ngOnInit() {
  }

  isSlidingPanelOpened(){
    return this.showFilter;
  }

  closeSlider(data: any, sliderType:string)
  {
    switch(sliderType)
    {
      case 'FILTER':
        this.showFilter = false;
        this.modelService.setOrderFilter(data);
        break;
    }
  }
  showFilterPanel()
  {
    this.showFilter = true;
  }

}
