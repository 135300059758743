import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FirstTimeChangePasswordArg, LoginArg, LoginResponseObj } from '../models/login';
import { GenericResponseObj } from '../models/responseobj';
import { OrderFilterObj, OrderListingObj, OrderObj } from '../models/order';
import { CurrentOrderStatusObj, NewOrderStatusArg } from '../models/orderstatus';
import { CostEstimationObj } from '../models/costestimate';
import { WorkReportObj, WRHourlyRateObj, WRProblemsObj, WRSparePartObj, WRTasksObj } from '../models/workreport';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  public login(arg: LoginArg): Observable<LoginResponseObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `loginTechnician`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      console.log(response);
        let authResponse: LoginResponseObj = new LoginResponseObj();
        
        authResponse.responseCode = response.responseCode;

        if(authResponse.responseCode == "00")
        {
            authResponse.userId = response.responseData.userId;
            authResponse.userName = response.responseData.userName;
            authResponse.isPasswordChanged = response.responseData.isPasswordChanged;
        }

        return authResponse
      }));
  
      return responseMapping$;
  }
  public changePasswordFirstTime(arg: FirstTimeChangePasswordArg): Observable<LoginResponseObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/changeTechnicianPasswordFirstTime`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
        let authResponse: LoginResponseObj = new LoginResponseObj();
        
        authResponse.responseCode = response.responseCode;

        if(authResponse.responseCode == "00")
        {
            authResponse.userId = response.responseData.userId;
            authResponse.userName = response.responseData.userName;
        }

        return authResponse
      }));
  
      return responseMapping$;
  }  

  public orderListing(arg: OrderFilterObj): Observable<OrderListingObj[]>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `getTechnicianOrders`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData as OrderListingObj[];
      }));
  
      return responseMapping$;
  }

  public getOrderById(orderId: number): Observable<OrderObj>
  {
    let arg = {
      "orderId" : orderId
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `getTechnicianOrderById`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0] as OrderObj;
      }));
  
      return responseMapping$;
  }

  public getOrderCurrentStatusObj(orderId: number): Observable<CurrentOrderStatusObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrderCurrentStatus/${orderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderCurrentStatusObj as CurrentOrderStatusObj;
      }));
  
      return responseMapping$;
  }

  public createNewOrderStatus(newOrderStatusArg: NewOrderStatusArg):Observable<GenericResponseObj>
  {
    newOrderStatusArg.statusBy = 'TECHNICIAN';
    
    let response$ = this.httpClient.post<any>(this.baseUrl + `/createNewOrderStatus`,newOrderStatusArg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response;
    }));
    return responseMapping$;
  }
  public getCostEstimationObj(orderId: number): Observable<CostEstimationObj>
  {
    let arg={
      "orderId":orderId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getCostEstimation`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0] as CostEstimationObj;
    }));
    return responseMapping$;
  }
  public saveCostEstimationObj(costEstimation:CostEstimationObj)
  {
        
    let arg =
    {
      orderId: costEstimation.orderId,
      repairHours: costEstimation.repairHours,
      repairMinutes: costEstimation.repairMinutes,
      hourlyRate: costEstimation.hourlyRate,
      lstSpareParts:JSON.stringify(costEstimation.lstSpareParts)
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/saveCostEstimation`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public acceptCostEstimation(orderId: number)
  {
    let arg =
    {
      orderId: orderId
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/acceptCostEstimation`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public declineCostEstimation(orderId: number, declineReason: string)
  {
    let arg =
    {
      orderId: orderId,
      declineReason: declineReason
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/declineCostEstimation`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }


  public saveWRTasks(wrTaskObj:WRTasksObj)
  {
    console.log(wrTaskObj);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/saveWRTasks`,wrTaskObj,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public saveWRProblems(wrProblemsObj:WRProblemsObj)
  {
    console.log(wrProblemsObj);
    
    let response$ = this.httpClient.post<any>(this.baseUrl + `/saveWRProblems`,wrProblemsObj,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public saveWRSpareParts(orderId: number, arrSpareParts:WRSparePartObj[])
  { 
    let arg =
    {
      orderId: orderId,
      lstSpareParts:JSON.stringify(arrSpareParts)
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/saveWRSpareParts`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public saveWRHourlyRate(wrHourlyRateObj:WRHourlyRateObj)
  {
    console.log(wrHourlyRateObj);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/saveWRHourlyRate`,wrHourlyRateObj,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
  public getWorkReport(orderId: number):Observable<WorkReportObj>
  {
    let arg={
      "orderId":orderId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getWorkReport`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let wrItem: WorkReportObj;
      if(response.responseData && response.responseData.length > 0)
      {
        wrItem = response.responseData[0] as WorkReportObj;

        if(!wrItem.lstSpareParts)
        {
          wrItem.lstSpareParts=[];
        }
        
        if(!wrItem.lstCESpareParts)
        {
          wrItem.lstCESpareParts = [];
        }
        

        wrItem.lstCESpareParts.forEach(ceSP=>{
         let wrSPItem = wrItem.lstSpareParts.find(wrSP=>wrSP.costEstimateSparePartId == ceSP.costEstimateSparePartId);
         if(!wrSPItem)
         {
           wrItem.lstSpareParts.push(ceSP);
         }
        });
      }
      else
      {
        wrItem = new WorkReportObj();
      }
      return wrItem;
    }));
    return responseMapping$;
  }

  public sendOrderCompletionEmail(orderId: number)
  { 
    let arg =
    {
      orderId: orderId,
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/sendOrderCompletionEmail`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
    return responseMapping$;
  }
}
