import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderFilterModeConstants } from '../shared/ccc/constants';
import { OrderFilterObj, OrderListingObj } from '../shared/models/order';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public isNoRecords: boolean = false;
  public lstOrders: OrderListingObj[] = [];


  constructor(private modelService: ModelService, private dataService: DataService, private router: Router) { }

  ngOnInit() {
    this.loadRecords();
  }

  public navigateToDetail(orderId: number)
  {
    this.router.navigate(['/orders-details',orderId]);
  }

  loadRecords()
  {
    let loginResponseObj = this.modelService.getLoginDetail();

    let filterObj:OrderFilterObj=new OrderFilterObj();
    filterObj.technicianId = loginResponseObj.userId;
    filterObj.mode= OrderFilterModeConstants.Today;

    this.dataService.orderListing(filterObj).subscribe(res=>{
      this.lstOrders = res;
      if(!this.lstOrders || this.lstOrders.length == 0)
      {
        this.isNoRecords = true;
      }
      else
      {
        this.isNoRecords = false;
      }
    });
  }

}
