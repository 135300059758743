import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WorkReportObj, WRProblemsObj } from 'src/app/shared/models/workreport';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'wbproblem',
  templateUrl: './wbproblem.component.html',
  styleUrls: ['./wbproblem.component.css'],
  animations: [
    trigger('slideInOut', [
      state('normal', style({
        height: '50px'
      })),
      state('expanded', style({
        overflow: 'auto',
        height: '300px'
      })),
      transition('normal => expanded', animate('400ms ease-out')),
      transition('expanded => normal', animate('400ms ease-out'))
    ])
  ]
})
export class WBProblemComponent implements OnInit {

  public form:FormGroup=null;

  public isProblemListExpanded: boolean = true;

  public isSolutionsListExpanded: boolean = false;

  public lstProblems:string[] = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.'
  ];

  public lstSolutions:string[] = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed tempus leo.'
  ];
  
  @Input()
  public workReportObj: WorkReportObj = null;
  
  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public selectedProblemIndex: number = null;
  public isProblemSelected: boolean = false;

  public selectedSolutionIndex: number = null;
  public isSolutionSelected: boolean = false;


  constructor(private toastr: ToastrManager, private fb: FormBuilder, private dataService: DataService) { 
    this.form=this.fb.group({
      problemTxt:[,[Validators.required]],
      solutionTxt:[,[Validators.required]]
    });
  }

  ngOnInit() {
    if(this.workReportObj)
    {
      if(this.workReportObj.problemTxt)
      {
        this.form.controls['problemTxt'].setValue(this.workReportObj.problemTxt);
        // this.selectedProblemIndex = this.lstProblems.indexOf(this.workReportObj.problemTxt);
        // this.isProblemSelected = true;
        // this.isProblemListExpanded = true;
      }
      if(this.workReportObj.solutionTxt)
      {
        this.form.controls['solutionTxt'].setValue(this.workReportObj.solutionTxt);
        // this.selectedSolutionIndex = this.lstProblems.indexOf(this.workReportObj.solutionTxt);
        // this.isSolutionSelected = true;
        // this.isSolutionsListExpanded = true;
      }
    }
  }

  next()
  {
    let problemObj: WRProblemsObj = new WRProblemsObj();
    problemObj.orderId = this.orderId;
    //problemObj.problemTxt =  this.lstProblems[this.selectedProblemIndex];
    //problemObj.solutionTxt  = this.lstSolutions[this.selectedSolutionIndex];
    problemObj.problemTxt = this.form.controls['problemTxt'].value;
    problemObj.solutionTxt  = this.form.controls['solutionTxt'].value;

    this.dataService.saveWRProblems(problemObj).subscribe(res=>{
      this.toastr.successToastr('Problem & Solution saved successfully', 'Step-3 Completed');
      this.onStepCompleted.emit(null); 
    });
  }

  toggleProblemExpand()
  {
    this.isProblemListExpanded = !this.isProblemListExpanded;
  }

  toggleSolutionExpand()
  {
    this.isSolutionsListExpanded = !this.isSolutionsListExpanded;
  }

  public editProblem(){
    this.isProblemSelected = false;
  }
  public selectProblem(selectedIndex: number)
  {
    this.selectedProblemIndex = selectedIndex;
    this.isProblemSelected = true;
  }

  public editSolution(){
    this.isSolutionSelected = false;
  }
  public selectSolution(selectedIndex: number)
  {
    this.selectedSolutionIndex = selectedIndex;
    this.isSolutionSelected = true;
  }

}
