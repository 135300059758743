import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WorkReportObj, WRHourlyRateObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'wbhourly-rate',
  templateUrl: './wbhourly-rate.component.html',
  styleUrls: ['./wbhourly-rate.component.css']
})
export class WBHourlyRateComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  
  public workReportObj: WorkReportObj = null;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public form:FormGroup = null;
  

  constructor(private toastr: ToastrManager, private fb: FormBuilder, private dataService: DataService) { 
    this.form = this.fb.group({
      drivingLumpSum: [,[Validators.required]],
      inspectionMinutes:[, [Validators.required]],
      repairMinutes:[, [Validators.required]],
      consultationMinutes:[, [Validators.required]],
      otherTaskMinutes:[, [Validators.required]],
      hourlyRate: [,[Validators.required]],
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  private loadRecords():void
  {
    this.dataService.getWorkReport(this.orderId).subscribe(res=>{
      
      this.workReportObj = res;

      this.form.controls["drivingLumpSum"].setValue(this.workReportObj.drivingLumpSum);
      this.form.controls["inspectionMinutes"].setValue(this.workReportObj.inspectionMinutes);
      this.form.controls["repairMinutes"].setValue(this.workReportObj.repairMinutes);
      this.form.controls["consultationMinutes"].setValue(this.workReportObj.consultationMinutes);
      this.form.controls["otherTaskMinutes"].setValue(this.workReportObj.otherTaskMinutes);
      this.form.controls["hourlyRate"].setValue(this.workReportObj.hourlyRate);
    });
  }

  next()
  {
    let hourlyRateObj: WRHourlyRateObj = { ...this.form.value};
    hourlyRateObj.orderId = this.orderId;
    this.dataService.saveWRHourlyRate(hourlyRateObj).subscribe(res=>{
      this.toastr.successToastr('Hourly rate saved successfully', 'Step-4 Completed');
      this.onStepCompleted.emit(null);
    });
  }

}
