export enum ModalConfirmationStatus { CLOSED, CONFIRMED };

export class OrderStatusConstants{
    public static readonly New:string="new";
    public static readonly Schedule:string="schedule";
    public static readonly Reschedule:string="reschedule";
    public static readonly InProgress:string="inprogress";
    public static readonly Complete:string="complete";
    public static readonly Cancel:string="cancel";
}
export class OrderFilterModeConstants{
    public static readonly Today:string="today";
    public static readonly Tomorrow:string="tomorrow";
    public static readonly Week:string="week";
    public static readonly DateRange:string="daterange";
}