import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wbspare-part-status',
  templateUrl: './wbspare-part-status.component.html',
  styleUrls: ['./wbspare-part-status.component.css']
})
export class WBSparePartStatusComponent implements OnInit {
  @Input()
  public index: number = 0;

  @Input()
  public orderId: number = 0;

  @Input()
  public form:FormGroup = null;

  @Output() 
  onSparePartStatusUpdated: EventEmitter<any> = new EventEmitter();

  public selectedStatus: string = '';

  constructor() 
  {
  }

  ngOnInit() {
  }

  getSparePartTitle():string
  {
    let spTitle : string = '';
    if(this.form)
    {
     spTitle = this.form.controls['itemName'].value;
    }

    return spTitle;
  }

  setSparePartStatus(status: string)
  {
    this.selectedStatus = status;
    this.form.controls['itemStatus'].setValue(status);
  }

  public updateSparePartStatus()
  {
    this.onSparePartStatusUpdated.emit({index: this.index});
  }


}
