<div class="welcomepage">
    <div class="fixfirstlogo">
        <svg width="238" height="70" viewBox="0 0 238 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.94792 69.9995V31.976H0V23.654H5.94792V21.2812C5.94792 10.4275 12.8799 1.96084 26.4078 1.96084C30.7392 1.96084 35.5026 3.09985 37.8786 4.65096L35.665 13.2274C33.6798 12.1083 30.4178 10.9818 26.4768 10.9818C18.9748 10.9818 16.6662 15.7684 16.6662 21.7273V23.654H41.5098V69.9995H30.7373V31.976H16.7205V69.9995H5.94792Z" fill="white"/>
            <path d="M104.219 69.9995V27.5962H97.5029V23.8461H104.219V21.0575C104.219 8.55749 109.924 -0.000320435 122.162 -0.000320435C126.118 -0.000320435 130.902 1.53822 133.203 3.4613L130.995 7.11539C128.878 5.38478 125.29 3.94227 121.333 3.94227C111.488 3.94227 108.728 12.2116 108.728 21.1539V23.8461H135.871V69.9995H131.363V27.5962H108.728V69.9995H104.219Z" fill="white"/>
            <path d="M151.595 38.6821C151.595 33.6714 151.503 28.7568 151.227 23.7465H155.376L155.561 33.7675H155.837C158.05 27.3114 162.845 22.59 169.392 22.59C170.129 22.59 170.867 22.6865 171.512 22.8789V27.2154C170.683 27.0225 169.945 27.0225 168.931 27.0225C162.752 27.0225 158.05 32.4186 156.575 40.4168C156.298 41.7657 156.113 43.4035 156.113 44.9457V70H151.595V38.6821Z" fill="white"/>
            <path d="M179.476 62.9265C182.025 64.5801 185.805 66.2333 190.024 66.2333C197.407 66.2333 200.659 62.1919 200.659 57.6909C200.659 52.5469 197.32 50.0663 190.727 47.862C183.08 45.2902 179.3 41.1568 179.3 35.9208C179.3 29.6746 184.046 23.7036 193.188 23.7036C197.32 23.7036 200.923 24.8973 203.384 26.6433L201.714 30.3173C200.308 29.3071 197.231 27.4699 192.397 27.4699C186.595 27.4699 183.519 31.1439 183.519 35.3693C183.519 40.3297 187.123 42.2585 193.364 44.4636C201.099 47.219 204.879 50.7093 204.879 57.323C204.879 64.4881 199.253 69.9993 189.672 69.9993C185.189 69.9993 181.058 68.7136 177.894 66.7844L179.476 62.9265Z" fill="white"/>
            <path d="M224.324 10.2981V23.7431H238V27.4093H224.324V56.2725C224.324 61.8201 225.944 66.0508 231.252 66.0508C233.861 66.0508 235.66 65.6747 236.919 65.2047L237.46 68.7773C235.931 69.4354 233.681 69.999 230.802 69.999C227.383 69.999 224.594 68.8713 222.796 66.6144C220.636 64.0762 219.916 59.9391 219.916 55.7084V27.4093H211.999V23.7431H219.916V11.7086L224.324 10.2981Z" fill="white"/>
            <path d="M95.2324 0.573971L71.1713 39.2839L61.3324 23.6655H50.4365L65.6309 46.8328H77.049L95.2324 18.1406V0.573971Z" fill="white"/>
            <path d="M50.4365 69.9995H61.3324L71.1713 54.3815L80.95 69.9995H92.1588L77.0486 46.8326H65.6309L50.4365 69.9995Z" fill="white"/>
        </svg>
    </div>
    <h3>Welcome to FixFirst</h3>
    <p>
        Our mission is to accelerate the transition to a circular future by creating a world where fixing comes first.
    </p>
    <div class="btnprimarycontainer">
        <button class="btnprimary" (click)="navigateToLogin()">Login</button>
    </div>
</div>