<div class="orderdetails" *ngIf="orderObj">
    <div class="orderbox">
        <div class="leftcol">
            <div class="icotoolbox">
                <div class="icotool"></div>
                <div class="tooltxt">
                    <h3>{{orderObj.visitType}}</h3>
                    <p class="pmain">FixFirst</p>
                </div>
            </div>
            <div class="leftinfocellsmall">{{orderObj.applianceType}}</div>
            <div class="leftinfocell"><span class="fa fa-calendar"></span>{{orderObj.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{orderObj.scheduleTime}}</div>
            <div class="leftinfocell" *ngIf="orderObj.customer"><span class="fa fa-map-marker"></span>{{orderObj.customer.visitAddressPostalCode}} {{orderObj.customer.visitAddressCity}}</div>
        </div>
        <div>
            <div class="rightinfocell">
                <span class="bdgbox" [statusbg]="orderObj.status">{{orderObj.status | orderstatus}}</span>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="infobox">
        <div class="titlerow">
            <h3>Spare parts & materials information</h3>
            <div class="showmorebox">
                <span>Show less <span class="fa  fa-chevron-up"></span></span>
            </div>
        </div>
        <div *ngFor="let item of orderObj.lstSpareParts; let i = index;">
            <div class="inforow">
                <span class="caption">ID # {{i + 1}}</span>
                <span class="txt">{{item.itemName}}</span>
            </div>
            <div class="inforow">
                <span class="caption">Code</span>
                <span class="txt">{{item.itemCode}}</span>
            </div>
            <div class="inforow">
                <span class="caption">Quantity</span>
                <span class="txt">{{item.itemQuantity}}</span>
            </div>
            <br />
        </div>
    </div>

    <div class="infobox">
        <div class="titlerow">
            <h3>Warranty information</h3>
            <div class="showmorebox">
                <span>Show less <span class="fa  fa-chevron-up"></span></span>
            </div>
        </div>
        <div class="inforow">
            <span class="caption">Status</span>
            <span class="txt">{{orderObj.applianceIsWarranty}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Contract</span>
            <span class="txt">{{orderObj.applianceWarrantyContractNo}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Details</span>
            <span class="txt">{{orderObj.applianceWarrantyDetails}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Notes</span>
            <span class="txt">{{orderObj.applianceWarrantyNotes}}</span>
        </div>
    </div>
    
    <div class="btnbox">
        <button class="btnprimary" (click)="showStatusPanel()">Update status</button>
    </div>
    </div>