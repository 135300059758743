<div class="wbheading">
    <img src="./../../../../assets/images/step5.png" />
    <h2>Summary</h2>
</div>
<div class="summarybox" *ngIf="workReportObj">
    <p class="infocaption">Order information</p>
    <div class="infobox">
        <div class="inforow">
            <label class="captiontxt">Order-ID:</label>
            <label class="valuetxt">{{workReportObj.fixFirstOrderId}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">FixFirst-ID:</label>
            <label class="valuetxt">{{workReportObj.fixFirstId}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Appliance:</label>
            <label class="valuetxt">{{workReportObj.applianceType}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Customer:</label>
            <label class="valuetxt">{{workReportObj.customerFirstName}} {{workReportObj.customerLastName}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Service provider:</label>
            <label class="valuetxt">{{workReportObj.spFirstName}} {{workReportObj.spLastName}}</label>
        </div>
        <div class="inforow">
            <label class="captiontxt">Feedback link:</label>
            <label class="valuetxt"><a target="_blank" [href]="getFeedbackLink()">Click here</a></label>
        </div>
    </div>
    <p class="infocaption">Tasks performed</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <tbody>
                <tr>
                    <th>Driving</th>
                    <td>{{workReportObj.drivingMinutes}} minutes</td>
                </tr>
                <tr>
                    <th>Inspection</th>
                    <td>{{workReportObj.inspectionMinutes}} minutes</td>
                </tr>
                <tr>
                    <th>Repairing</th>
                    <td>{{workReportObj.repairMinutes}} minutes</td>
                </tr>
                <tr>
                    <th>Consultation</th>
                    <td>{{workReportObj.consultationMinutes}} minutes</td>
                </tr>
                <tr>
                    <th>Other tasks</th>
                    <td>{{workReportObj.otherTaskMinutes}} minutes</td>
                </tr>
            </tbody>
        </table>
    </div>
    <p class="infocaption">Problem</p>
    <div class="infobox">
        {{workReportObj.problemTxt}}
    </div>
    <p class="infocaption">Solution</p>
    <div class="infobox">
        {{workReportObj.solutionTxt}}
    </div>
    <p class="infocaption">Material & spare parts</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Price (€)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of workReportObj.lstSpareParts">
                    <td>{{item.itemName}}</td>
                    <td>{{item.itemQuantity}}</td>
                    <td>{{item.itemPrice}}</td>
                </tr>
                <tr>
                    <td><strong>Subtotal</strong></td>
                    <td></td>
                    <td><strong>{{workReportObj.sparePartsTotal.toFixed(2)}}</strong></td>
                </tr>
                <tr>
                    <td><strong>Tax 21%</strong></td>
                    <td></td>
                    <td><strong>{{workReportObj.sparePartsTax.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <p class="infocaption">Hour rate</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <th>Concept</th>
                    <th>Qty</th>
                    <th>Price (€)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Labour rate
                        <br />
                        @ {{workReportObj.hourlyRate}} / hour
                    </td>
                    <td>{{workReportObj.labourHours}},{{workReportObj.labourMinutes}}</td>
                    <td>{{workReportObj.labourTotal.toFixed(2)}}</td>
                </tr>
                <tr>
                    <td>Driving Lump sum</td>
                    <td></td>
                    <td>{{workReportObj.drivingLumpSum}}</td>
                </tr>
                <tr>
                    <td><strong>Subtotal</strong></td>
                    <td></td>
                    <td><strong>{{(workReportObj.labourTotal + workReportObj.drivingLumpSum).toFixed(2)}}</strong></td>
                </tr>
                <tr>
                    <td><strong>Tax 21%</strong></td>
                    <td></td>
                    <td><strong>{{workReportObj.labourTax.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <p class="infocaption">TOTAL (€)</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <tbody>
                <tr>
                    <td>Materials & spare parts</td>
                    <td>{{(workReportObj.sparePartsTotal + workReportObj.sparePartsTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td>Driving + Labour Total</td>
                    <td>{{(workReportObj.drivingLumpSum + workReportObj.labourTotal + workReportObj.labourTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td><strong>Grand total</strong></td>
                    <td><strong>{{workReportObj.grandTotal.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="btnbox">
        <button class="btnprimary" (click)="next()">Finish</button>
    </div>
</div>