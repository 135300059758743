import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ng6-toastr-notifications';
import { MatExpansionModule, MatSidenavModule, MatSlideToggleModule } from '@angular/material';
import {MatSliderModule} from '@angular/material/slider';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTPListener, HTTPStatus } from './shared/services/httplistener.service';
import { WelcomeComponent } from './login/welcome/welcome.component';
import { LoginComponent } from './login/login.component';
import { ChangeFirstPasswordComponent } from './login/change-first-password/change-first-password.component';
import { SecureComponent } from './secure/secure.component';
import { OrdersListLayoutComponent } from './orders-list-layout/orders-list-layout.component';
import { OrdersListComponent } from './orders-list-layout/orders-list/orders-list.component';
import { OrdersCalendarComponent } from './orders-list-layout/orders-calendar/orders-calendar.component';
import { OrderDetailsComponent } from './order-details-layout/order-details/order-details.component';
import { OrderDetailsLayoutComponent } from './order-details-layout/order-details-layout.component';
import { HomeComponent } from './home/home.component';
import { StatusBgDirective } from './shared/directives/status-bg.directive';
import { StatusIcoDirective } from './shared/directives/status-ico.directive';
import { OrdersFilterComponent } from './orders-list-layout/orders-filter/orders-filter.component';
import { CustomDatePipe } from './shared/pipes/custom-data.pipe';
import { OrderStatusPipe } from './shared/pipes/order-status.pipe';
import { UpdateStatusComponent } from './order-details-layout/update-status/update-status.component';
import { MoreToolsComponent } from './more-tools/more-tools.component';
import { CostEstimateComponent } from './more-tools/cost-estimate/cost-estimate.component';
import { SparePartsComponent } from './more-tools/cost-estimate/spare-parts/spare-parts.component';
import { CostEstimateSummaryComponent } from './more-tools/cost-estimate/cost-estimate-summary/cost-estimate-summary.component';
import { ManageCostEstimateComponent } from './more-tools/cost-estimate/manage-cost-estimate.component';
import { AcceptEstimationComponent } from './more-tools/cost-estimate/accept-estimation/accept-estimation.component';
import { DeclineEstimationComponent } from './more-tools/cost-estimate/decline-estimation/decline-estimation.component';
import { ManageWorkBookComponent } from './more-tools/manage-work-book/manage-work-book.component';
import { WBTasksComponent } from './more-tools/manage-work-book/wbtasks/wbtasks.component';
import { WBSparePartsComponent } from './more-tools/manage-work-book/wbspare-parts/wbspare-parts.component';
import { WBProblemComponent } from './more-tools/manage-work-book/wbproblem/wbproblem.component';
import { WBWorkProofComponent } from './more-tools/manage-work-book/wbwork-proof/wbwork-proof.component';
import { WBSignaturesComponent } from './more-tools/manage-work-book/wbsignatures/wbsignatures.component';
import { WBSparePartStatusComponent } from './more-tools/manage-work-book/wbspare-parts/wbspare-part-status/wbspare-part-status.component';
import { VisitPreparationComponent } from './order-details-layout/visit-preparation/visit-preparation.component';
import { WBSparePartUnitComponent } from './more-tools/manage-work-book/wbspare-parts/wbspare-part-unit/wbspare-part-unit.component';
import { WBHourlyRateComponent } from './more-tools/manage-work-book/wbhourly-rate/wbhourly-rate.component';
import { WBSummaryComponent } from './more-tools/manage-work-book/wbsummary/wbsummary.component';
import { CustomDateAdapter, CustomDateParserFormatter } from './shared/services/custom-date.adapter';

@NgModule({
  declarations: [
    AppComponent,

    WelcomeComponent,
    LoginComponent,
    ChangeFirstPasswordComponent,

    SecureComponent,

    HomeComponent,
    
    OrdersListLayoutComponent,
    OrdersFilterComponent,
    OrdersListComponent,
    OrdersCalendarComponent,

    OrderDetailsLayoutComponent,
    UpdateStatusComponent,
    OrderDetailsComponent,
    VisitPreparationComponent,

    MoreToolsComponent,

    ManageCostEstimateComponent,
    CostEstimateComponent,
    SparePartsComponent,
    CostEstimateSummaryComponent,
    AcceptEstimationComponent,
    DeclineEstimationComponent,

    ManageWorkBookComponent,
    WBTasksComponent,
    WBSparePartsComponent,
    WBSparePartUnitComponent,
    WBSparePartStatusComponent,
    WBProblemComponent,
    WBHourlyRateComponent,
    WBWorkProofComponent,
    WBSignaturesComponent,
    WBSummaryComponent,
    

    StatusBgDirective, StatusIcoDirective, CustomDatePipe, OrderStatusPipe

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    MatSidenavModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatSliderModule,

    NgxSpinnerModule 
  ],
  providers:[    
    { provide: APP_BASE_HREF, useValue: '/' }, 
    { provide: LocationStrategy, useClass: HashLocationStrategy }, HTTPListener, HTTPStatus,
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true }, { provide: 'BASE_URL', useFactory: getBaseUrl },
    {provide: NgbDateAdapter, useClass: CustomDateAdapter}, {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  exports:[MatSliderModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getBaseUrl() {
  return 'https://2xfiolj702.execute-api.eu-central-1.amazonaws.com/dev/';
  // return 'http://localhost:3000/dev';
}
