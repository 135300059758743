<div class="orderdetails" *ngIf="orderObj">
<div class="orderbox">
    <div class="leftcol">
        <div class="icotoolbox">
            <div class="icotool"></div>
            <div class="tooltxt">
                <h3>{{orderObj.visitType}}</h3>
                <p class="pmain">FixFirst</p>
            </div>
        </div>
        <div class="leftinfocellsmall">{{orderObj.applianceType}}</div>
        <div class="leftinfocell"><span class="fa fa-calendar"></span>{{orderObj.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{orderObj.scheduleTime}}</div>
        <div class="leftinfocell" *ngIf="orderObj.customer"><span class="fa fa-map-marker"></span>{{orderObj.customer.visitAddressPostalCode}} {{orderObj.customer.visitAddressCity}}</div>
    </div>
    <div>
        <div class="rightinfocell">
            <span class="bdgbox" [statusbg]="orderObj.status">{{orderObj.status | orderstatus}}</span>
            <div class="clearfix"></div>
        </div>
    </div>
</div>

<div class="infobox">
    <div class="titlerow">
        <h3>IDs</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <div class="inforow">
        <span class="caption">FixFirst ID</span>
        <span class="txt">{{orderObj.fixtFirstID}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Order ID</span>
        <span class="txt">{{orderObj.fixFirstOrderID}}</span>
    </div>
</div>

<div class="infobox">
    <div class="titlerow">
        <h3>Appliance information</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <div class="inforow">
        <span class="caption">Appliance</span>
        <span class="txt">{{orderObj.applianceType}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Brand</span>
        <span class="txt">{{orderObj.applianceBrand}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Model number</span>
        <span class="txt">{{orderObj.applianceModelNumber}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Serial number</span>
        <span class="txt">{{orderObj.applianceSerialNumber}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Index number</span>
        <span class="txt">{{orderObj.applianceIndexNumber}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Year</span>
        <span class="txt">{{orderObj.applianceAge}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Contract</span>
        <span class="txt">{{orderObj.applianceContractNumber}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Warranty</span>
        <span class="txt">{{orderObj.applianceIsWarranty}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Price new (€)</span>
        <span class="txt">{{orderObj.applianceNewPrice}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Level of usage</span>
        <span class="txt">{{orderObj.applianceLevelOfUsage}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Installation</span>
        <span class="txt">{{orderObj.applianceInstallationDetail}}</span>
    </div>
</div>

<div class="infobox">
    <div class="titlerow">
        <h3>Error information</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <div class="inforow">
        <span class="caption">Problem</span>
        <span class="txt">{{orderObj.errorTitle}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Error code</span>
        <span class="txt">{{orderObj.errorCode}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Details</span>
        <span class="txt">{{orderObj.errorDetails}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Notes</span>
        <span class="txt">{{orderObj.errorNotes}}</span>
    </div>
</div>


<div class="infobox" *ngIf="orderObj.customer">
    <div class="titlerow">
        <h3>Contact information</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <div class="inforow">
        <span class="caption">First name</span>
        <span class="txt">{{orderObj.customer.contactFirstName}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Last name</span>
        <span class="txt">{{orderObj.customer.contactLastName}}</span>
    </div>
    <div class="inforow">
        <span class="caption">E-mail</span>
        <span class="txt">{{orderObj.customer.contactEmail}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Phone number</span>
        <span class="txt">{{orderObj.customer.contactMobileNumberExtension}} {{orderObj.customer.contactMobileNumber}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Whatsapp</span>
        <span class="txt">{{orderObj.customer.contactWhatsAppContactAllowed}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Languages</span>
        <span class="txt">{{orderObj.customer.contactLanguages}}</span>
    </div>
</div>

<div class="infobox" *ngIf="orderObj.customer">
    <div class="titlerow">
        <h3>Visit address</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <div class="inforow">
        <span class="caption">Address</span>
        <span class="txt">{{orderObj.customer.visitAddressStreetNo}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Number</span>
        <span class="txt">{{orderObj.customer.visitAddressHouseNo}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Addition</span>
        <span class="txt">{{orderObj.customer.visitAddressAdditionInfo}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Postal code</span>
        <span class="txt">{{orderObj.customer.visitAddressPostalCode}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Country</span>
        <span class="txt">{{orderObj.customer.visitAddressCountry}}</span>
    </div>
    <div class="inforow">
        <span class="caption">City</span>
        <span class="txt">{{orderObj.customer.visitAddressCity}}</span>
    </div>
    <div class="inforow">
        <span class="caption">Notes</span>
        <span class="txt">{{orderObj.customer.visitAddressNotes}}</span>
    </div>
</div>

<div class="infobox" *ngIf="orderObj.customer">
    <div class="titlerow">
        <h3>Billing address</h3>
        <div class="showmorebox">
            <span>Show less <span class="fa  fa-chevron-up"></span></span>
        </div>
    </div>
    <p *ngIf="orderObj.customer.isBillingAddressSame">Billing address is the same as the Visit address </p>
    <div *ngIf="!orderObj.customer.isBillingAddressSame">
        <div class="inforow">
            <span class="caption">Address</span>
            <span class="txt">{{orderObj.customer.billingAddressStreetNo}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Number</span>
            <span class="txt">{{orderObj.customer.billingAddressHouseNo}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Addition</span>
            <span class="txt">{{orderObj.customer.billingAddressAdditionInfo}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Postal code</span>
            <span class="txt">{{orderObj.customer.billingAddressPostalCode}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Country</span>
            <span class="txt">{{orderObj.customer.billingAddressCountry}}</span>
        </div>
        <div class="inforow">
            <span class="caption">City</span>
            <span class="txt">{{orderObj.customer.billingAddressCity}}</span>
        </div>
        <div class="inforow">
            <span class="caption">Notes</span>
            <span class="txt">{{orderObj.customer.billingAddressNotes}}</span>
        </div>
    </div>
</div>
<div class="btnbox">
    <button class="btnprimary" (click)="showStatusPanel()">Update status</button>
</div>
</div>