<h2 class="small">Acceptance</h2>
<div class="acceptbox">
    <div class="alertbox">
        <p class="heading">Conditions</p>
        <p class="txt">Period of validity 1 month. Price changes reserved.</p>
    </div>

    <p class="infocaption">TOTAL (€)</p>
    <div class="infobox">
        <table class="table table-bordered table-sm table-striped">
            <tbody>
                <tr>
                    <td>Materials & spare parts</td>
                    <td>{{(costEstimationObj.sparePartsTotal + costEstimationObj.sparePartsTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td>Hour rate</td>
                    <td>{{(costEstimationObj.hourlyRateTotal + costEstimationObj.hourlyRateTax).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td><strong>Grand total</strong></td>
                    <td><strong>{{costEstimationObj.grandTotal.toFixed(2)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btnbox">
        <button class="btnprimary" (click)="accept()">Accept</button>
    </div>
</div>