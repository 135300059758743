import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OrderStatusConstants } from 'src/app/shared/ccc/constants';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { NewOrderStatusArg } from 'src/app/shared/models/orderstatus';
import { WorkReportObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'manage-work-book',
  templateUrl: './manage-work-book.component.html',
  styleUrls: ['./manage-work-book.component.css']
})
export class ManageWorkBookComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  public workReportObj: WorkReportObj = null;

  @Output() 
  sliderClosed: EventEmitter<any> = new EventEmitter();

  public panelMode:string = 'TASK';

  constructor(private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.loadRecords();
  }

  close()
  {
    this.sliderClosed.emit(null);
  }
  stepCompleted(data: any, panelMode: string)
  {
      switch(panelMode)
      {
        case 'TASK':
          this.panelMode = 'SPARE_PARTS';
          break;
        case 'SPARE_PARTS':
            this.panelMode = 'PROBLEMS';
          break;
        case 'PROBLEMS':
          this.panelMode = 'HOURLY_RATE';
          break;
        case 'HOURLY_RATE':
          this.panelMode = 'SUMMARY'
            break;
        // case 'WORK_PROOF':
        //   this.panelMode = 'SIGNATURE';
        //   break;
          // case 'SIGNATURE':
          //   this.close();
          //   break;
          case 'SUMMARY':
            this.completeOrder();
            this.close();
            break;
      }
  }

  private loadRecords():void
  {
    this.dataService.getWorkReport(this.orderId).subscribe(res=>{
      this.workReportObj = res;
    });
  }

  public isBackBtnVisible():boolean
  {
    let isVisible: boolean = false;
    if(this.panelMode == "SPARE_PARTS" || this.panelMode == "PROBLEMS" || this.panelMode == "WORK_PROOF" || this.panelMode == "SIGNATURE")
    {
      isVisible= true;
    }

    return isVisible;
  }
  public moveBack():void
  {
    switch(this.panelMode)
    {
      case "SPARE_PARTS":
        this.panelMode = "TASK";
        break;
      case "PROBLEMS":
        this.panelMode = "SPARE_PARTS";
        break;
      case "WORK_PROOF":
        this.panelMode = "PROBLEMS";
        break;
      case "SIGNATURE":
        this.panelMode = "WORK_PROOF";
        break;
    }
  }

  public completeOrder():void
  {
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();

    let newStatusArg: NewOrderStatusArg=new NewOrderStatusArg();
    newStatusArg.orderId = this.orderId;
    newStatusArg.status = OrderStatusConstants.Complete;
    newStatusArg.statusReason = "";
    newStatusArg.userId = loginDetail.userId;
    this.dataService.createNewOrderStatus(newStatusArg).subscribe(res=>{
      switch(res.responseCode)
      {
        case "00":
          this.toastr.successToastr('Order was completed successfully', 'Order Completed');
          this.dataService.sendOrderCompletionEmail(this.orderId).subscribe(res=>{
            this.toastr.successToastr('Order completion email successfully sent.', 'Email sent');
          });
          break;
        case "01":
          this.toastr.errorToastr('Status could not be updated, because the status of this order is already set as completed.', 'Error!');
          break;
      }
    });
  }

}
