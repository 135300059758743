<mat-drawer-container class="formgroup-sidenavcontainer" [hasBackdrop]="true">
    <mat-drawer #sidenav mode="over" [opened]="isSlidingPanelOpened()" position="end" class="filtersidenav" [disableClose]="true">
      <div class="matDrawerContent">
          <div *ngIf="showCostEstimate">
              <manage-cost-estimate [orderId]="orderId" (sliderClosed)='closeSlider($event, "COST_ESTIMATE")'></manage-cost-estimate>
          </div>
          <div *ngIf="showWorkBook">
            <manage-work-book [orderId]="orderId" (sliderClosed)='closeSlider($event, "WORK_BOOK")'></manage-work-book>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content> 
        <div class="moretoolscontainer">
            <div class="moretoolsbox">
                <div class="icobox">
                    <span class="fa fa-map-marker"></span>
                </div>
                <div class="icotxt">Start navigation</div>
                <div class="icotxt2">Musterstraße 34</div>
            </div>
            <div class="moretoolsbox">
                <div class="icobox">
                    <span class="fa fa-video-camera"></span>
                </div>
                <div class="icotxt">Start video call</div>
                <div class="icotxt2">+49 174 977 34 08</div>
            </div>
            <div class="moretoolsbox">
                <div class="icobox">
                    <span class="fa fa-phone"></span>
                </div>
                <div class="icotxt">Call client</div>
                <div class="icotxt2">+49 174 977 34 08</div>
            </div>
            <div class="moretoolsbox">
                <div class="icobox">
                    <span class="fa fa-envelope"></span>
                </div>
                <div class="icotxt">Send message</div>
                <div class="icotxt2">+49 174 977 34 08</div>
            </div>
            <div class="moretoolsbox" (click)="showCostEstimatePanel()">
                <div class="icobox">
                    <span class="fa fa-calculator"></span>
                </div>
                <div class="icotxt">Estimate</div>
                <div class="icotxt2">Start calculation</div>
            </div>
            <div class="moretoolsbox" (click)="showWorkBookPanel()">
                <div class="icobox">
                    <span class="fa fa-file"></span>
                </div>
                <div class="icotxt">Work report</div>
                <div class="icotxt2">Work report</div>
            </div>
        </div>

    </mat-drawer-content>
  </mat-drawer-container>