import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostEstimationObj } from 'src/app/shared/models/costestimate';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'cost-estimate-summary',
  templateUrl: './cost-estimate-summary.component.html',
  styleUrls: ['./cost-estimate-summary.component.css']
})
export class CostEstimateSummaryComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public costEstimationObj: CostEstimationObj=null;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadRecords();
  }
  acceptEstimation()
  {
    this.onStepCompleted.emit({mode:'ACCEPT'});
  }
  declineEstimation()
  {
    this.onStepCompleted.emit({mode:'DECLINE'});
  }
  private loadRecords():void{
    this.dataService.getCostEstimationObj(this.orderId).subscribe(res=>{
      this.costEstimationObj = res;
    });
  }

}
