import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderFilterModeConstants } from 'src/app/shared/ccc/constants';
import { OrderFilterObj, OrderListingObj } from 'src/app/shared/models/order';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit {

  public isNoRecords: boolean = false;
  public lstOrders: OrderListingObj[] = [];
  private filterObj:OrderFilterObj=null;
  constructor(private modelService: ModelService, private dataService: DataService, private router: Router) { }

  ngOnInit() {
    this.loadRecords();
    this.modelService.getOrderFilter().subscribe(filterObj=>{
      this.filterObj = filterObj;
      this.loadRecords();
    });
  }

  public navigateToDetail(orderId: number)
  {
    this.router.navigate(['/orders-details',orderId]);
  }

  
  loadRecords()
  {
    let loginResponseObj = this.modelService.getLoginDetail();

    if(!this.filterObj)
    {
      this.filterObj=new OrderFilterObj();
      
      this.filterObj.technicianId = loginResponseObj.userId;
      this.filterObj.mode= OrderFilterModeConstants.Today;
    }

    this.dataService.orderListing(this.filterObj).subscribe(res=>{
      this.lstOrders = res;
      
      if(!this.lstOrders || this.lstOrders.length == 0)
      {
        this.isNoRecords = true;
      }
      else
      {
        this.isNoRecords = false;
      }

    });
  }

}
