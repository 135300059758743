import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-order-details-layout',
  templateUrl: './order-details-layout.component.html',
  styleUrls: ['./order-details-layout.component.css']
})
export class OrderDetailsLayoutComponent implements OnInit {

  public orderId: number = null;

  public showUpdateStatus: boolean = false;

  constructor(private modelService: ModelService, public activatedRoute: ActivatedRoute, public router: Router) { 
    this.modelService.getStautsPanel().subscribe(res=>{
        this.showUpdateStatus = res;
    });
  }

  ngOnInit() {
    this.orderId = this.activatedRoute.firstChild.snapshot.params.id;
  }

  navigateToMoreTools()
  {
    this.router.navigate(['/more-tools', this.orderId]);
  }

  isSlidingPanelOpened(){
    return this.showUpdateStatus;
  }
  closeSlider(data: any, sliderType:string)
  {
    switch(sliderType)
    {
      case 'UPDATE_STATUS':
        this.modelService.setStautsPanel(false);
        if(data && data.isUpdated)
        {
          this.modelService.setReloadOrderDetail(true);
        }
        break;
    }
  }

}
