<h2 class="small">Decline</h2>
<div class="declinebox">
    <p class="infocaption">Reason for declining</p>
    <p class="infotxt">Select reason for declining the estimation</p>

    <div class="statusbox">
        <div class="statusitem selectable" [class.selected]="declineReason == 'Client found cheaper alternative'" (click)="selectDeclineReason('Client found cheaper alternative')">
            <div class="statusTxt">Client found cheaper alternative</div>
            <div class="selectedIco" *ngIf="declineReason == 'Client found cheaper alternative'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="declineReason == 'Client found faster alternative'" (click)="selectDeclineReason('Client found faster alternative')">
            <div class="statusTxt">Client found faster alternative</div>
            <div class="selectedIco" *ngIf="declineReason == 'Client found faster alternative'">
                <span class="fa fa-check"></span>
            </div>
        </div>
        <div class="statusitem selectable" [class.selected]="declineReason == 'Client prefers to replace the appliance'" (click)="selectDeclineReason('Client prefers to replace the appliance')">
            <div class="statusTxt">Client prefers to replace the appliance</div>
            <div class="selectedIco" *ngIf="declineReason == 'Client prefers to replace the appliance'">
                <span class="fa fa-check"></span>
            </div>
        </div>
    </div>

    <div class="btnbox">
        <button class="btnprimary" (click)="sendFeedback()">Decline</button>
    </div>
</div>