import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'manage-cost-estimate',
  templateUrl: './manage-cost-estimate.component.html',
  styleUrls: ['./manage-cost-estimate.component.css']
})
export class ManageCostEstimateComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  @Output() 
  sliderClosed: EventEmitter<any> = new EventEmitter();

  public panelMode:string='FORM';

  constructor() { }

  ngOnInit() {
  }

  close()
  {
    this.sliderClosed.emit(null);
  }
  stepCompleted(data: any, panelMode: string)
  {
      switch(panelMode)
      {
        case 'FORM':
          this.panelMode = 'SUMMARY';
          break;
        case 'SUMMARY':
          if(data )
          {
            switch(data.mode)
            {
              case "ACCEPT":
                this.panelMode = "ACCEPT";
                break;
              case "DECLINE":
                this.panelMode = "DECLINE";
                  break;
            }
          }
          break;
        case 'ACCEPT':
          this.close();
          break;
        case 'DECLINE':
          this.close();
          break;
      }
  }

}
