import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginResponseObj } from '../models/login';
import { OrderFilterObj } from '../models/order';
@Injectable({
  providedIn: 'root'
})
export class ModelService {
  
  private showStatusPanel$= new BehaviorSubject<boolean>(false);
  private filterObj$ = new BehaviorSubject<OrderFilterObj>(null);
  private reloadOrderDetail$ = new BehaviorSubject<boolean>(false);


  constructor() { }

  setLoginDetail(loginDetail: LoginResponseObj) {
    localStorage.setItem("login", JSON.stringify(loginDetail));
  }
  getLoginDetail():LoginResponseObj {
    return JSON.parse(localStorage.getItem("login"));
  }
  clearLocalStorage()
  {
    localStorage.clear();
  }

  public setStautsPanel(panelStatus: boolean) {
    this.showStatusPanel$.next(panelStatus);
  }
  public getStautsPanel() {
    return this.showStatusPanel$.asObservable();
  }

  public setOrderFilter(orderFilter: OrderFilterObj) {
    this.filterObj$.next(orderFilter);
  }
  public getOrderFilter() {
    return this.filterObj$.asObservable();
  }

  public setReloadOrderDetail(reload: boolean) {
    this.reloadOrderDetail$.next(reload);
  }
  public getReloadOrderDetail() {
    return this.reloadOrderDetail$.asObservable();
  }
}
