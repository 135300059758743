<div style="position: relative">
    <ngx-spinner></ngx-spinner>
  </div>
  <div class="layoutcontent">
    <router-outlet></router-outlet>
  </div>
  <div class="layoutfooter">
    <div class="footerico" [class.selected]="isRouteSelected('/home')" (click)="navigateToHome()">
      <div class="fa fa-home"></div>
      <span>Home</span>
    </div>
    <div class="footerico" [class.selected]="isRouteSelected('/orders-list')" (click)="navigateToOrders()">
      <div class="fa fa-list-alt"></div>
      <span>Orders</span>
    </div>
    <div class="footerico" (click)="logout()">
      <div class="fa fa-sign-out"></div>
      <span>Log out</span>
    </div>
  </div>