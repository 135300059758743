export class OrderFilterObj
{
    public technicianId: number;
    public mode: string;
    public dateFrom: any;
    public dateTo: any;
    
    public statusArr: string[]=[];
    public visitTypeArr: string[]=[];
}
export class OrderListingObj{
    public orderId: number;
    public visitType: string;
    public applianceType: string;
    public customerPostalCode: string;
    public customerCity: string;
    public status: string;
    public scheduleDate: any;
    public scheduleTime: string;
}
export class CustomerObj
{
    public contactFirstName: string;
    public contactLastName: string;
    public contactEmail: string;
    public contactMobileNumberExtension: string;
    public contactMobileNumber: string;
    public contactWhatsAppContactAllowed: boolean;
    public contactLanguages: string;
    public additionalNotes: string;
    public visitAddressHouseNo: string;
    public visitAddressStreetNo: string;
    public visitAddressAdditionInfo: string;
    public visitAddressNotes: string;
    public visitAddressCity: string;
    public visitAddressPostalCode: string;
    public visitAddressCountry: string;
    public visitAddressIsElevator: boolean;
    public isBillingAddressSame: boolean;
    public billingAddressHouseNo: string;
    public billingAddressStreetNo: string;
    public billingAddressAdditionInfo: string;
    public billingAddressNotes: string;
    public billingAddressCity: string;
    public billingAddressPostalCode: string;
    public billingAddressCountry: string;
}
export class OrderObj
{
    public orderId: number;
    
    public status: string;
    
    public visitType: string;

    public fixtFirstID: number;
    public fixFirstOrderID: string;

    public noOfAppliances: number;
    public applianceType: string;
    public applianceBrand: string;
    public applianceModelNumber: string;
    public applianceSerialNumber: string;
    public applianceIndexNumber: string;
    public applianceAge: number;
    public applianceContractNumber: string;
    public applianceIsWarranty: boolean;
    public applianceWarrantyContractNo: string;
    public applianceWarrantyDetails: string;
    public applianceWarrantyNotes: string;
    public applianceInstallationDetail: string;
    public applianceLevelOfUsage: string;
    public appliancePurchaseDate: any;
    public applianceNewPrice: number;

    public errorTitle: string;
    public errorCode: string;
    public errorDetails: string;
    public errorNotes: string;

    public insightRecallInfo: string;
    public insightOther: string;

    public recommendationRepair: string;
    public recommendationOther: string;

    public scheduleDate: any;
    public scheduleTime: string;

    public customer: CustomerObj;
    public lstSpareParts: OrderSparePartObj[] = [];
}
export class OrderSparePartObj
{
    public itemName: string;
    public itemCode: string;
    public itemQuantity: number;
}
export class OrderCommentsObj
{
    public commentText: string;
    public commentDate: any;
    public commentBy: string;
    public spName: string;
    public customerName: string;
    public technicianName: string;
}