import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../shared/services/data.service';
import { HTTPStatus } from '../shared/services/httplistener.service';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit {

  constructor(private httpStatus: HTTPStatus, private dataService: DataService, private modelService: ModelService, private spinner: NgxSpinnerService, private router: Router) { 
    this.httpStatus.getHttpStatus().subscribe(status => { 
      if(status)
      {
        this.spinner.show();
      }
      else
      {
        this.spinner.hide();
      }
     });
  }

  ngOnInit() {
  }

  isRouteSelected(route: string):boolean
  {
    if(this.router.url.startsWith(route))
    {
      return true;
    }
    return false;
  }

  navigateToHome()
  {
    this.router.navigate(['/home']);
  }
  navigateToOrders()
  {
    this.router.navigate(['/orders-list']);
  }

  logout()
  {
    this.modelService.clearLocalStorage();
    this.router.navigate(['/welcome']);
  }

}
