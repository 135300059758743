import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OrderObj } from 'src/app/shared/models/order';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  public orderObj: OrderObj = null;
  public orderId: number = 0;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) 
  { 
    this.orderId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.loadRecords();
    this.modelService.getReloadOrderDetail().subscribe(res=>{
      if(res)
      {
        this.loadRecords();
      }
    })
  }

  public showStatusPanel()
  {
    this.modelService.setStautsPanel(true);
  }

  private loadRecords()
  {
    this.dataService.getOrderById(this.orderId).subscribe(res=>{
      console.log(res);
      this.orderObj = res;
    });
  }

}
