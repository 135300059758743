import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WorkReportObj, WRTasksObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'wbtasks',
  templateUrl: './wbtasks.component.html',
  styleUrls: ['./wbtasks.component.css']
})
export class WBTasksComponent implements OnInit {
  
  @Input()
  public workReportObj: WorkReportObj = null;

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public form:FormGroup = null;
  

  constructor(private toastr: ToastrManager, private fb: FormBuilder, private dataService: DataService) {
    this.form = this.fb.group({
      drivingMinutes: [0],
      inspectionMinutes: [0],
      repairMinutes: [0],
      consultationMinutes: [0],
      otherTaskMinutes: [0]
    });
   }

  ngOnInit() {
    this.loadRecords();
  }

  next()
  {
    let taskObj: WRTasksObj = { ...this.form.value};
    taskObj.orderId = this.orderId;

    console.log(taskObj);

    this.dataService.saveWRTasks(taskObj).subscribe(res=>{
      this.toastr.successToastr('Tasks details saved successfully', 'Step-1 Completed');
      this.onStepCompleted.emit(null); 
    });
  }
  private loadRecords():void
  {
    if(this.workReportObj)
    {
      
      this.form.controls['drivingMinutes'].setValue(this.workReportObj.drivingMinutes || 0);
      this.form.controls['inspectionMinutes'].setValue(this.workReportObj.inspectionMinutes || 0);
      this.form.controls['repairMinutes'].setValue(this.workReportObj.repairMinutes || 0);
      this.form.controls['consultationMinutes'].setValue(this.workReportObj.consultationMinutes || 0);
      this.form.controls['otherTaskMinutes'].setValue(this.workReportObj.otherTaskMinutes || 0);
    }
  }

}
