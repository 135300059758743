
<div class="topheadingbox">
    <h1>Today’s orders</h1>
    <div class="rightico">
        <span class="fa fa-question"></span>
    </div>
</div>
<div class="searchbox">
    <input class="searchtxt" type="text" placeholder="Search" />
</div>
<div *ngIf="isNoRecords" class="norecordsbox">
    <h3>No orders today.</h3>
    <br /><br />
    <h3>Please come back later or update your profile.</h3>
</div>
<div *ngIf="!isNoRecords" class="orderlisting">
    <div class="orderbox" *ngFor="let item of lstOrders" (click)="navigateToDetail(item.orderId)">
        <div class="leftcol">
            <div class="icotoolbox">
                <div class="icotool"></div>
                <div class="tooltxt">
                    <h3>{{item.visitType}}</h3>
                    <p class="pmain">FixFirst</p>
                </div>
            </div>
            <div class="leftinfocellsmall">{{item.applianceType}}</div>
            <div class="leftinfocell"><span class="fa fa-calendar"></span>{{item.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{item.scheduleTime}}</div>
            <div class="leftinfocell"><span class="fa fa-map-marker"></span>{{item.customerPostalCode}} {{item.customerCity}}</div>
        </div>
        <div>
            <div class="rightinfocell">
                <span class="bdgbox" [statusbg]="item.status">{{item.status | orderstatus}}</span>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>