export class CurrentOrderStatusObj{
    public orderId: number;
    public scheduleDate: any;
    public scheduleTime: string;
    public visitAddressPostalCode: string;
    public visitAddressCity: string;
    public status: string;
    public statusReason: string;
}
export class NewOrderStatusArg{
    public orderId: number;
    public status: string;
    public statusReason: string;
    public statusBy: string;
    public userId: number;
}