<div class="cancellink" (click)="close()">
    <span>Cancel</span>
</div>
<h1>Filters</h1>

<h3>Date</h3>
<div class="filtersearchbox">
    <div class="searchitem">
        <div class="searchTxt">Today</div>
        <div class="filterswitch">
            <input type="radio" name="moderadio" value="today" id="switchtoday" switch="success" [(ngModel)]="mode">
            <label for="switchtoday"></label>
        </div>
    </div>
    <div class="searchitem">
        <div class="searchTxt">Tomorrow</div>
        <div class="filterswitch">
            <input type="radio" name="moderadio" value="tomorrow" id="switchtomorrow" switch="success" [(ngModel)]="mode">
            <label for="switchtomorrow"></label>
        </div>
    </div>
    <div class="searchitem">
        <div class="searchTxt">This week</div>
        <div class="filterswitch">
            <input type="radio" name="moderadio" value="week" id="switchweek" switch="success" [(ngModel)]="mode">
            <label for="switchweek"></label>
        </div>
    </div>
    <div class="searchitem">
        <div class="searchTxt">Date range</div>
        <div class="filterswitch">
            <input type="radio" name="moderadio" value="daterange" id="switchdaterange" switch="success" [(ngModel)]="mode">
            <label for="switchdaterange"></label>
        </div>
    </div>
    <div *ngIf="mode == 'daterange'" class="daterangebox form-horizontal">
        <div class="form-group">
            <label>From</label>
            <input type="text" ngbDatepicker [(ngModel)]="fromDate" class="form-control txtboxbg" #dateFrom="ngbDatepicker" (click)="dateFrom.toggle()"/>
        </div>
        <div class="form-group">
            <label>To</label>
            <input type="text" ngbDatepicker [(ngModel)]="toDate" class="form-control txtboxbg" #dateTo="ngbDatepicker" (click)="dateTo.toggle()"/>
        </div>
    </div>
</div>

<h3>Status</h3>
<div class="filtersearchbox">
    <div class="searchitem selectable" [class.selected]="isStatusSelected('schedule')" (click)="selectStatus('schedule')">
        <div class="searchTxt">Scheduled</div>
        <div class="selectedIco" *ngIf="isStatusSelected('schedule')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isStatusSelected('reschedule')" (click)="selectStatus('reschedule')">
        <div class="searchTxt">Rescheduled</div>
        <div class="selectedIco" *ngIf="isStatusSelected('reschedule')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isStatusSelected('inprogress')" (click)="selectStatus('inprogress')">
        <div class="searchTxt">In progress</div>
        <div class="selectedIco" *ngIf="isStatusSelected('inprogress')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isStatusSelected('complete')" (click)="selectStatus('complete')">
        <div class="searchTxt">Completed</div>
        <div class="selectedIco" *ngIf="isStatusSelected('complete')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isStatusSelected('cancel')" (click)="selectStatus('cancel')">
        <div class="searchTxt">Canceled</div>
        <div class="selectedIco" *ngIf="isStatusSelected('cancel')">
            <span class="fa fa-check"></span>
        </div>
    </div>
</div>

<h3>Visit type</h3>
<div class="filtersearchbox">
    <div class="searchitem selectable" [class.selected]="isVisitTypeSelected('On-Site')" (click)="selectVisitType('On-Site')">
        <div class="searchTxt">On-site</div>
        <div class="selectedIco" *ngIf="isVisitTypeSelected('On-Site')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isVisitTypeSelected('Phone-Call')" (click)="selectVisitType('Phone-Call')">
        <div class="searchTxt">Phone call</div>
        <div class="selectedIco" *ngIf="isVisitTypeSelected('Phone-Call')">
            <span class="fa fa-check"></span>
        </div>
    </div>
    <div class="searchitem selectable" [class.selected]="isVisitTypeSelected('Video-Call')" (click)="selectVisitType('Video-Call')">
        <div class="searchTxt">Video call</div>
        <div class="selectedIco" *ngIf="isVisitTypeSelected('Video-Call')">
            <span class="fa fa-check"></span>
        </div>
    </div>
</div>

<div class="btnbox">
    <button class="btnprimary" [disabled]="isBtnDisabled()" (click)="applyFilters()">Apply filters</button>
</div>