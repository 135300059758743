import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-more-tools',
  templateUrl: './more-tools.component.html',
  styleUrls: ['./more-tools.component.css']
})
export class MoreToolsComponent implements OnInit {

  public showCostEstimate: boolean = false;
  public showWorkBook: boolean = false;

  public orderId: number = null;

  constructor(private activatedRoute: ActivatedRoute) { 
    this.orderId = +this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
  }

  isSlidingPanelOpened(){
    return this.showCostEstimate || this.showWorkBook;
  }

  closeSlider(data: any, sliderType:string)
  {
    switch(sliderType)
    {
      case 'COST_ESTIMATE':
        this.showCostEstimate = false;
        break;
        case 'WORK_BOOK':
          this.showWorkBook = false;
          break;
    }
  }

  showCostEstimatePanel()
  {
    this.showCostEstimate = true;
  }
  showWorkBookPanel()
  {
    this.showWorkBook = true;
  }

}
