import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CostEstimationObj } from 'src/app/shared/models/costestimate';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'cost-estimate',
  templateUrl: './cost-estimate.component.html',
  styleUrls: ['./cost-estimate.component.css']
})
export class CostEstimateComponent implements OnInit {
  
  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public form:FormGroup = null;
  
  constructor(private dataService: DataService, private fb: FormBuilder, private toastr: ToastrManager) { 
    this.form = this.fb.group({
      repairHours: [, [Validators.required]],
      repairMinutes: [, []],
      hourlyRate: [, [Validators.required]],
      lstSpareParts: this.fb.array([])
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  get lstSpareParts():FormArray {
    return this.form.get('lstSpareParts') as FormArray;
  };

  addSparePart()
  {
    this.lstSpareParts.push(this.fb.group({
      itemName: ['', [Validators.required]],
      itemQuantity: [, [Validators.required]],
      itemPrice: [, [Validators.required]]
    }));
  }

  private loadRecords():void
  {
    this.dataService.getCostEstimationObj(this.orderId).subscribe(res=>{
      
      console.log(res);

      this.form.controls["repairHours"].setValue(res.repairHours);
      this.form.controls["repairMinutes"].setValue(res.repairMinutes);
      this.form.controls["hourlyRate"].setValue(res.hourlyRate);
      
        if(res.lstSpareParts)
        {
          res.lstSpareParts.forEach(x => {
            this.lstSpareParts.push(this.fb.group({
              itemName: [x.itemName, [Validators.required]],
              itemQuantity: [x.itemQuantity, [Validators.required]],
              itemPrice: [x.itemPrice, [Validators.required]]
            }));
          });
        }
    });
  }

  calculate()
  {
    let costEstimateObj:CostEstimationObj =  {...this.form.value};
    costEstimateObj.orderId = +this.orderId;

    this.dataService.saveCostEstimationObj(costEstimateObj).subscribe(res=>{
      this.toastr.successToastr('cost estimation saved successfully');
      this.onStepCompleted.emit(null);
    });
  }

}
