<div class="wbheading">
    <img src="./../../../../assets/images/step4.png" />
    <h2>Hourly Rate</h2>
</div>
<div [formGroup]="form" *ngIf="workReportObj">
    <p class="infocaption">Input lump sum charges for driving</p>
    <div class="row2">
        <div class="form-group" style="width:180px;">
            <label>Driving Lump sum (€)</label>
            <input type="number" class="form-control" formControlName="drivingLumpSum" />
            <small>for <strong>{{workReportObj.drivingMinutes}} minutes</strong> driving</small>
        </div>
    </div>

    <p class="infocaption">Please review the tasks durations and adjust if required.</p>
    <table class="table table-bordered table-striped">
        <thead>
            <tr>
                <th>Task</th>
                <th style="width:60px;">Duration <br />(minutes)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Inspection</td>
                <td>
                    <input type="number" class="form-control" formControlName="inspectionMinutes" />
                </td>
            </tr>
            <tr>
                <td>Repair</td>
                <td>
                    <input type="number" class="form-control" formControlName="repairMinutes" />
                </td>
            </tr>
            <tr>
                <td>Consultation</td>
                <td>
                    <input type="number" class="form-control" formControlName="consultationMinutes" />
                </td>
            </tr>
            <tr>
                <td>Other tasks</td>
                <td>
                    <input type="number" class="form-control" formControlName="otherTaskMinutes" />
                </td>
            </tr>
        </tbody>
    </table>
    <div class="row2">
        <div class="form-group" style="width:150px;">
            <label>Hourly rate (€)</label>
            <input type="number" class="form-control" formControlName="hourlyRate" />
        </div>
    </div>
</div>
<div class="btnbox">
    <button class="btnprimary" [disabled]="!form.valid" (click)="next()">Next</button>
</div>