<div class="materialsbox" [formGroup]="form">
    <h2 class="small">Materials & spare parts</h2>

    <p class="infocaption">Input the materials and spare parts that we’ll be used and their estimated price</p>
    <div *ngFor="let item of lstSpareParts.controls; let i=index">
        <spare-parts [serialNo]="i + 1" [form]="item"></spare-parts>
    </div>
    <div class="addLinkBox">
        <a (click)="addSparePart()"><span class="fa fa-plus-circle"></span> Add spare parts or material</a>
    </div>

    <h2 class="small">Hour rate</h2>
    <p class="infocaption">Input the hourly rate</p>
    <div class="row1">
        <div class="form-group" style="width:150px;">
            <label>Hours</label>
            <input type="number" class="form-control" formControlName="repairHours" />
        </div>
        <div class="form-group" style="width:150px;">
            <label>Minutes</label>
            <input type="number" class="form-control" formControlName="repairMinutes" />
        </div>
    </div>
    <div class="row2">
        <div class="form-group">
            <label>Hourly rate (€)</label>
            <input type="number" class="form-control" formControlName="hourlyRate" />
        </div>
    </div>
    <div class="btnbox">
        <button class="btnprimary" (click)="calculate()">Calculate</button>
    </div>
</div>