import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LoginArg, LoginResponseObj } from '../shared/models/login';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form: FormGroup = null;

  constructor(private fb: FormBuilder, private dataService: DataService, private modelService: ModelService,  private toastr: ToastrManager, private router: Router) { 
    this.form= this.fb.group({
      emailAddress:[, [Validators.required, Validators.maxLength(100)]],
      password: [, [Validators.required, Validators.maxLength(100)]]
    });
  }

  ngOnInit() {
  }
  login(){
    let arg:LoginArg=new LoginArg();
    arg.email = this.form.value.emailAddress;
    arg.password = this.form.value.password;

    this.dataService.login(arg).subscribe((res:LoginResponseObj)=>{
      console.log('login details are ', res);
      if(res.responseCode=="00")
      { 
        if(!res.isPasswordChanged)
        {
          this.toastr.infoToastr('Please set a new password before login', 'Set new password!')
          this.router.navigate(['first-login-change-password', arg.email]);
        }
        else
        {
          this.modelService.setLoginDetail(res);
          this.router.navigate(['/home']);
        }
      }
      else
      {
        this.toastr.errorToastr("Invalid Login / Password","Login Failed");
      }
    });
  }

}
