import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WorkReportObj, WRSparePartObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'wbspare-parts',
  templateUrl: './wbspare-parts.component.html',
  styleUrls: ['./wbspare-parts.component.css']
})
export class WBSparePartsComponent implements OnInit {

  public mode: string = "SPARE_PARTS";
  public sparePartsArrIndex: number = null;
  
  @Input()
  public workReportObj: WorkReportObj = null;

  @Input()
  public orderId: number = 0;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  public form:FormGroup = null;

  public newSparePartTitle: string = "";


  constructor(private dataService: DataService, private fb: FormBuilder, private toastr: ToastrManager) { 
    this.form = this.fb.group({
      lstSpareParts: this.fb.array([])
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  get lstSpareParts():FormArray {
    return this.form.get('lstSpareParts') as FormArray;
  };

  addSparePart()
  {
    if(!this.newSparePartTitle)
    {
      this.toastr.errorToastr("Please enter spare part title", "Error!");
    }
    else
    {
      this.lstSpareParts.push(this.fb.group({
        itemName: [this.newSparePartTitle, [Validators.required]],
        itemStatus: [, [Validators.required]],
        itemQuantity:[, [Validators.required]],
        itemPrice: [, [Validators.required]],
        costEstimateSparePartId: []
      }));
      this.newSparePartTitle="";
    }
  }
  
  next()
  {  
   let spArr:WRSparePartObj[] = Object.values({...this.lstSpareParts.value});

   
   this.dataService.saveWRSpareParts(this.orderId, spArr).subscribe(res=>{
    this.toastr.successToastr('Spare parts saved successfully', 'Step-2 Completed');
    this.onStepCompleted.emit(null);
   });
  }
  
  private loadRecords():void
  {
      if(this.workReportObj && this.workReportObj.lstSpareParts)
      {
        console.log(this.workReportObj);
        this.workReportObj.lstSpareParts.forEach(x => {
          this.lstSpareParts.push(this.fb.group({
            itemName: [x.itemName, [Validators.required]],
            itemStatus:[x.itemStatus, [Validators.required]],
            itemQuantity:[x.itemQuantity, [Validators.required]],
            itemPrice:[x.itemPrice, [Validators.required]],
            costEstimateSparePartId: [x.costEstimateSparePartId]
          }));
        });
      }
  }

  public showUpdateSparePartsPanel(data: any)
  {
    this.sparePartsArrIndex = data.index;
    this.mode = "SPARE_PARTS_STATUS";
  }
  public updateSparePartStatus(data: any)
  {
    let frm: any = this.lstSpareParts.controls[data.index];
    this.mode = "SPARE_PARTS";
  }

}