import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderFilterModeConstants } from 'src/app/shared/ccc/constants';
import { Util } from 'src/app/shared/ccc/util';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { OrderFilterObj } from 'src/app/shared/models/order';
import { ModelService } from 'src/app/shared/services/model-service';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'orders-filter',
  templateUrl: './orders-filter.component.html',
  styleUrls: ['./orders-filter.component.css']
})
export class OrdersFilterComponent implements OnInit {

  @Output() sliderClosed: EventEmitter<any> = new EventEmitter();

  public mode: string = 'today';

  public filterObj: OrderFilterObj=new OrderFilterObj();

  public fromDate:any = null;
  public toDate:any = null;

  constructor(private modelService: ModelService, private toastr: ToastrManager) { 


  }

  isStatusSelected(status: string):boolean{
    if(this.filterObj.statusArr.find(x=>x == status))
    {
      return true;
    }
    return false;
  }
  isVisitTypeSelected(visitType: string):boolean{
    if(this.filterObj.visitTypeArr.find(x=>x == visitType))
    {
      return true;
    }
    return false;
  }

  selectStatus(status: string):void{
    if(this.filterObj.statusArr.find(x=>x == status))
    {
      this.filterObj.statusArr.splice(this.filterObj.statusArr.lastIndexOf(status) , 1);
    }
    else
    {
      
      this.filterObj.statusArr.push(status);
    }
  }
  selectVisitType(visitType: string):void{
    if(this.filterObj.visitTypeArr.find(x=>x == visitType))
    {
      this.filterObj.visitTypeArr.splice(this.filterObj.statusArr.lastIndexOf(visitType) , 1);
    }
    else
    {
      
      this.filterObj.visitTypeArr.push(visitType);
    }
  }

  ngOnInit() {
  }
  close()
  {
    this.sliderClosed.emit(null);
  }

  isBtnDisabled():boolean
  {
    let isDisabled:boolean = false;
    if(this.mode == OrderFilterModeConstants.DateRange)
    {
      if(!this.fromDate || !this.toDate)
      {
        isDisabled = true;
      }
    }
    return isDisabled;
  }

  applyFilters()
  {
    console.log(this.fromDate);
    
    let loginResponseObj: LoginResponseObj = this.modelService.getLoginDetail();
    this.filterObj.technicianId = loginResponseObj.userId;
    this.filterObj.mode = this.mode;

    if(this.mode == OrderFilterModeConstants.DateRange)
    {
      this.filterObj.dateFrom = Util.NgbDateToString(Util.ParseCustomDate(this.fromDate));
      this.filterObj.dateTo = Util.NgbDateToString(Util.ParseCustomDate(this.toDate));

      if(moment(this.filterObj.dateFrom) > moment(this.filterObj.dateTo))
      {
        this.toastr.errorToastr('from date can not be after to date','Error!');
        return;
      }
    }

    this.sliderClosed.emit(this.filterObj);
  }
}
