import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wbspare-part-unit',
  templateUrl: './wbspare-part-unit.component.html',
  styleUrls: ['./wbspare-part-unit.component.css']
})
export class WBSparePartUnitComponent implements OnInit {

  @Input()
  public index: number = null;

  @Input()
  public form:FormGroup = null;

  @Output() 
  onStepCompleted: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  updateStatus()
  {
    this.onStepCompleted.emit({mode: 'SPARE_PART_STATUS', index: this.index});
  }

}
